import { evolutionLevels, getChainId, getOpenSeaApiUrl, OpenSeaApiKey } from 'components/Constants';
import queryString from 'query-string';

export const normalizeBaton = (data, id) => {
  const normalized = {
    ...data,
    id: data.token_id || id,
    members: [],
  };

  const attributes = data.attributes || data.traits;

  if (Array.isArray(attributes)) {
    attributes.forEach(attr => {
      let name = attr.trait_type;
      const value = attr.value;
      if (!name || name === 'property') {
        normalized.members.push(value);
      }
      else {
        const key = name.replace(/[^a-z0-9]/ig, '_').toLowerCase();
        normalized[key] = value;
      }
    });
  }
  normalized.evolution = (normalized.evolution || 'common').toLowerCase();
  normalized.evolution_level = evolutionLevels.indexOf(normalized.evolution);
  normalized.is_normalized = true;
  normalized.token_id = normalized.id; // alias
  normalized.image = normalized.image || normalized.image_url; // alias
  return normalized;
}

export const shortenString = (string, max) => {
  return string.substring(0, (max / 2) - 1) + '...' + string.substring(string.length - (max / 2) + 2, string.length)
}

export const getIpfsGatewayUrl = (ipfsUrl) => {
  return (ipfsUrl || '').replace('ipfs://', 'https://passthebaton.mypinata.cloud/ipfs/');
}

export const fetchOpensea = async (path, options) => {
  var chainId = options.chainId || getChainId();
  var url = getOpenSeaApiUrl(chainId) + path;
  var { params } = options;

  const headers = {};
  if (chainId === 1) {
    headers['x-api-key'] = OpenSeaApiKey;
  }

  if (!(params && Object.keys(params).length === 0 && params.constructor === Object)) {
    url += `?${queryString.stringify(params)}`
  }

  const resp = await fetch(url, {
    headers: headers
  })
  return await resp.json()
}