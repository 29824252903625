import { useEffect, useState } from 'react';
import { useEthers } from '@usedapp/core';
import { utils } from 'ethers';
import { useContract } from 'hooks/useContract';
import { contractType, getOpenSeaUrl } from 'components/Constants';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import PendingTransactionModal from 'components/PendingTransactionModal';
import allowMap from 'shared/first500.json';
import { merkleProofFor } from 'shared/drawingHelpers';
import { getDrawingEventId } from 'components/Constants';
import 'css/common.scss';
import 'css/drawing.scss';
import tommieDrawing from 'assets/images/misc/tommie_drawing.jpg';

const IS_DEBUG = false;

const Drawing = () => {
  const { account, chainId } = useEthers()
  const [ error, setError ] = useState();
  const [ claimableDate, setClaimableDate ] = useState(null);
  const [ claimableAmount, setClaimableAmount ] = useState(null);
  const [ allowedUser, setAllowedUser ] = useState();
  const [ showPending, setShowPending ] = useState(false);
  const [ txnId, setTxnId ] = useState('');
  const contract = useContract(contractType.drawing);

  const mintDrawing = async () => {
    const allowList = Object.entries(allowMap).map(([key, value]) => ({
      address: key,
      amount: value
    }));
    const proof = merkleProofFor(allowList, allowedUser);

    try {
      let result;
      if (IS_DEBUG) {
        result = {hash: '0xabc123', wait: () => new Promise(resolve => setTimeout(resolve, 5000))};
      }
      else {
        result = await contract.claim(getDrawingEventId(chainId), 1, allowedUser.amount, proof);
      }
      if (result.hash) {
        setTxnId(result.hash);
        setShowPending(true);
      }
      await result.wait(1);
      setShowPending(false);
      const openSeaUrl = `${getOpenSeaUrl(chainId)}/${account}`;
      setError(<div>
        Successfully minted!<br/>
        <a className="ptb__button" href={openSeaUrl} rel="noreferrer" target="_blank">View on OpenSea</a>
      </div>)
    }
    catch (e) {
      console.error(e.message)
      if (/Not mintable/.test(e.message)) {
        setError('NFT is no longer mintable');
      }
    }
  }

  useEffect(() => {
    let allowedUser;

    if (account) {
      const addrKey = Object.keys(allowMap).find(key => 
        utils.getAddress(account) === utils.getAddress(key)
      );
      if (addrKey && Number.isInteger(allowMap[addrKey])) {
        allowedUser = {
          address: account,
          amount: allowMap[addrKey]
        };
      }

      setAllowedUser(allowedUser);
    }

    (async () => {
      try {
        account ? setError(null) : setError('Please connect your wallet');
        if (account) {
          !allowedUser ? setError('You are not on the allowed list') : setError(null);
        }
      } catch (err) {
        if (err.toString().indexOf("owner query for nonexistent token") > -1) {
          setError('This token does not exist.');
        }
      }

    })();
  }, [account]);

  useEffect(() => {
    if (!contract || !account || !allowedUser || !chainId) return;
    (async () => {
      const drawingEventId = getDrawingEventId(chainId);
      const claimableUntil = (await contract.claimableUntil(drawingEventId)).toNumber();
      const claimed = (await contract.claimed(drawingEventId, account)).toNumber();
      const claimable = allowedUser.amount - claimed;
      const claimableDate = moment.unix(claimableUntil);
      setClaimableDate(`${claimableDate.format("MMMM Do YYYY, h:mm a")} ${moment.tz(moment.tz.guess()).zoneAbbr()}`);
      setClaimableAmount(claimable);
    })();
  }, [contract, account, chainId]);

  const renderError = () => {
    return (
      <div className="details error">
        <div className="details__title">{error}</div>
        <Link className="ptb__button" to="/gallery">Visit the Baton Gallery</Link>
      </div>
    )
  }

  const render = () => <>
    <div className="drawing">
      <div className="drawing__content" src={tommieDrawing}>
        <div className="drawing__title" src={tommieDrawing}>
          Relay
        </div>
        <img className="drawing__image" src={tommieDrawing}/>
        <div className="drawing__button">
          <button className="ptb__button" onClick={mintDrawing}>
            Mint Your Relay NFT
          </button>
          {claimableDate &&
            <div className="drawing__claimable">{claimableAmount} NFT{claimableAmount === 1 ? '' : 's'} claimable until {claimableDate}</div>
          }
        </div>
        <div className="drawing__description">
          Limited to the first 500 minters of Pass the Baton NFTs as a gesture of gratitude for their early and enthusiastic support, Relay is an NFT of the drawing gifted by Glenn and Tommie to President Barack Obama on the trip to the Oval Office that sparked the Pass the Baton project. Based on an image from Tommie Smith’s vast collection of images of his victories, the drawing depicts Tommie passing the baton to a fourth runner in world-record time. On the back of the framed work, Tommie included an inscription to the President. He wrote, “I can only imagine the length of passage. But most importantly, the baton was not dropped,” instigating a conversation about how human rights was an intergenerational and intersectional relay that served as the inspiration for the Pass the Baton movement.
        </div>
      </div>
    </div>

    { txnId && <PendingTransactionModal
      txnId={txnId}
      isOpen={Boolean(showPending)}
      onClose={() => setShowPending(false)}
    /> }
  </>

  return error ? renderError() : render();
}

export default Drawing;