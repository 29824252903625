import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useUpdateBaton } from 'hooks/useUpdateBaton';
import ColoredBar from 'components/gallery/ColoredBar';
import 'css/gallery/baton.scss';
import placeholderImg from 'assets/images/placeholder.png';

const BatonItem = ({ baton, milestones, skipUpdate }) => {
  let imageUrl = baton.image;
  if (!imageUrl || /\bwww\.example\.com\b/.test(imageUrl)) {
    imageUrl = placeholderImg;
  }

  const { batonDonations, batonEvolution, batonImage, batonTeam } = useUpdateBaton(baton, skipUpdate);

  useEffect(() =>{
    if (!Array.isArray(milestones) || milestones.length === 0) { return }
  }, [milestones])

  return <Link className="gallery__row" to={`/baton/${baton.token_id}`}>
    <span>
      <img src={batonImage} alt={`Baton #${baton.token_id}`} />
    </span>
    <span>
      <strong>Baton #{baton.token_id}</strong>
    </span>
    <span>{batonTeam}</span>
    <span>{parseFloat(batonDonations || baton.donated_amount || 0).toFixed(2)} ETH Donated</span>
    <span>
      <ColoredBar
        donatedAmount={batonDonations}
        evolution={batonEvolution}
        team={batonTeam}
        milestones={milestones}
      />
    </span>
  </Link>
}

export default BatonItem;