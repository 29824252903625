import { useEffect, useState, useCallback, useRef } from 'react';
import { usePublicBatons } from 'hooks/useBatons';
import { useContract } from 'hooks/useContract';
import { useSigner } from 'hooks/useSigner';
import { useEthers } from '@usedapp/core';
import { useParams } from "react-router-dom";
import { AvatarApiUrl, contractType, getOpenSeaUrl } from 'components/Constants';
import { Link } from 'react-router-dom';
import Loader from 'components/Loader';
import { normalizeBaton, getIpfsGatewayUrl } from 'shared/utils';
import AvatarRenderer from 'shared/AvatarRenderer';
import PendingTransactionModal from 'components/PendingTransactionModal';
import 'css/common.scss';
import 'css/avatar.scss';
import plusIcon from 'assets/images/avatar/plus_icon.svg';
import minusIcon from 'assets/images/avatar/minus_icon.svg';
import upIcon from 'assets/images/avatar/up_icon.svg';
import downIcon from 'assets/images/avatar/down_icon.svg';
import leftIcon from 'assets/images/avatar/left_icon.svg';
import rightIcon from 'assets/images/avatar/right_icon.svg';

const IS_DEBUG = false;

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

const getAvatarAssets = async () => {
  const resp = await window.fetch(`${AvatarApiUrl}/avatar_assets/`);
  return await resp.json();
}

const BatonAvatar = () => {
  const batons = usePublicBatons();
  const profileContract = useContract(contractType.profile);
  const canvasRef = useRef();
  const avatarRenderer = useRef();
  const imageAssets = useRef();
  const { account, chainId } = useEthers()
  const { tokenId } = useParams();
  const [batonData, setBatonData] = useState();
  const [claimData, setClaimData] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [downloadLink, setDownloadLink] = useState();
  const [txHash, setTxHash] = useState();
  const [assetList, setAssetList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [error, setError] = useState();
  const signer = useSigner();
  const [ allowedUser, setAllowedUser ] = useState();
  const [ showPending, setShowPending ] = useState(false);
  const [ txnId, setTxnId ] = useState();

  const selectionData = useRef({
    avatarId: 0,
    colors: [
      hexToRgb('#958d37'),
      hexToRgb('#cc6565'),
      hexToRgb('#b4dcd1'),
    ]
  });

  const onColorChange = (option, hex) => {
    const colorArray = selectionData.current.colors;
    switch (option) {
      case 'hair': colorArray[0] = hexToRgb(hex); break;
      case 'skin': colorArray[1] = hexToRgb(hex); break;
      case 'shirt': colorArray[2] = hexToRgb(hex); break;
    }
    renderImage();
  }

  const loadAvatar = async (index, list, batonImage) => {
    const renderer = avatarRenderer.current;
    await renderer.setImageData({
      grayscaleImage: (list || assetList)[index].gray,
      colormapImage: (list || assetList)[index].color,
      batonImage,
      circleFullImage: imageAssets.current.background.circleBg,
      circleHalfImage: imageAssets.current.background.circleFg
    });
  }

  const renderImage = () => {
    const renderer = avatarRenderer.current;
    const canvasEl = canvasRef.current;
    renderer.renderImage(canvasEl, selectionData.current.colors);
  }

  const getBatonData = useCallback(async () => {
    if (!batons) { return }
    console.log({batons});

    const path = await batons.tokenURI(tokenId);
    const response = await window.fetch(getIpfsGatewayUrl(path));
    let batonData = await response.json();
    const charityGroup = await batons.charityGroupForToken(tokenId);
    if (charityGroup) {
      batonData.charity_group = charityGroup.toNumber();
    }
    batonData.token_id = tokenId;
    batonData = normalizeBaton(batonData, tokenId);
    setBatonData(batonData);

    // Find transaction hash
    const filteredResults = await batons.queryFilter(
      batons.filters.Transfer(undefined, account)
    );
    const matchedResult = filteredResults.find(result => 
      result.args.tokenId.toNumber() === parseInt(tokenId)
    );

    if (matchedResult) {
      setTxHash(matchedResult.transactionHash);
    }
  }, [batons, tokenId, chainId]);

  const save = () => {
    const apiUrl = `${AvatarApiUrl}/avatar/${tokenId}`;
    if (!signer || !txHash) {
      window.alert('Unable to find baton transaction');
      return;
    }

    setIsSaving(true);
    signer.signMessage('Save profile picture.').then(async signature => {
      const data = {
        signature,
        avatarId: selectionData.current.avatarId,
        colors: selectionData.current.colors,
        chainId,
        txHash
      };
      const resp = await window.fetch(apiUrl, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const json = await resp.json();
      if (json.error) {
        window.alert(json.error);
      }
      else {
       setClaimData(json);
      }
      setIsSaving(false);
    });
  }

  const mint = async () => {
    try {
      if (claimData) {
        let result;
        const { sig, imageUri } = claimData;
        if (IS_DEBUG) {
          result = {hash: '0xabc123', wait: () => new Promise(resolve => setTimeout(resolve, 5000))};
        }
        else {
          console.log('claim params:', JSON.stringify({tokenId, imageUri, sig}))
          result = await profileContract.claim(tokenId, imageUri, sig);
          console.log({result});
        }
        if (result.hash) {
          setTxnId(result.hash);
          setShowPending(true);
        }
        await result.wait(1);
        setShowPending(false);
        const openSeaUrl = `${getOpenSeaUrl(chainId)}/${account}`;
        setError(<div>
          Successfully minted!<br/>
          <a className="ptb__button" href={openSeaUrl} rel="noreferrer" target="_blank">View on OpenSea</a>
        </div>)
      }
      else {
        setError('Cannot locate transaction');
      }
    }
    catch(e) {
      if (/Already claimed/.test(e.message)) {
        setError('Profile picture already claimed');
      }
    }
  }

  const selectThumb = async index => {
    const batonImageUrl = getIpfsGatewayUrl(batonData.image).replace(/\/\d\/baton.png/, '/0/baton.png');
    const list = [...assetList];
    assetList.forEach((asset, i) => {
      asset.selected = index === i;
    });
    setAssetList(list);
    await loadAvatar(index, null, batonImageUrl);
    renderImage();
    selectionData.current.avatarId = index;
  }

  useEffect(() => {
    let isOwner = false;

    if (!batons) { return }

    (async () => {
      try {
        if (account) {
          const batonOwner = await batons.ownerOf(tokenId);
          isOwner = batonOwner === account;
          getBatonData();
        }
      } catch (err) {
        if (err.toString().indexOf("owner query for nonexistent token") > -1) {
          setError('This token does not exist.');
        }
      }
      setError(isOwner ? null : 'You do not own this baton');
      setIsOwner(isOwner)
    })();
  }, [batons, tokenId, account, getBatonData]);

  useEffect(() => {
    if (!canvasRef.current || !batonData) return;
    (async () => {
      avatarRenderer.current = new AvatarRenderer(2048, 2048);
      const batonImageUrl = getIpfsGatewayUrl(batonData.image).replace(/\/\d\/baton.png/, '/0/baton.png');
      const assets = await getAvatarAssets();
      assets.images[0].selected = true;
      setAssetList(assets.images);
      setColorList(assets.colors);
      imageAssets.current = assets;

      const canvasEl = canvasRef.current;
      canvasEl.width = 2048;
      canvasEl.height = 2048;
      await loadAvatar(0, assets.images, batonImageUrl);
      renderImage()
    })();
  }, [canvasRef, batonData])

  const renderError = () => <div className="details error">
    <div className="details__title">{error}</div>
    <Link className="ptb__button" to="/gallery">Visit the Baton Gallery</Link>
  </div>

  const renderBaton = () => <div className="avatar">
    <div className="avatar__title ptb__mobile">
      Baton Holder PFP Generator
    </div>
    <div className="avatar__thumbs">
      <div className="avatar__thumbs-arrow">
        <img className="ptb__desktop" src={upIcon}/>
        <img className="ptb__mobile" src={leftIcon}/>
      </div>
      <div className="avatar__thumbs-container">
        {assetList.map((asset, index) =>
          <div
            key={index}
            className={`avatar__thumb-item ${asset.selected ? 'avatar__thumb-item--selected' : ''}`}
            style={{ backgroundImage: `url(${asset.thumb})` }}
            onClick={() => selectThumb(index)}
          />
        )}
      </div>
      <div className="avatar__thumbs-arrow">
        <img className="ptb__desktop" src={downIcon}/>
        <img className="ptb__mobile" src={rightIcon}/>
      </div>
    </div>
    <div className="avatar__canvas">
      <canvas className="avatar__canvas-item" ref={canvasRef}></canvas>
    </div>
    <div className="avatar__settings">
      <div className="avatar__title ptb__desktop">
        Baton Holder PFP Generator
      </div>
      <div className="avatar__description">
        Baton holders can build and customize a unique PFP featuring their actual baton. Select your avatar’s hair, skin, and shirt color, and then mint as an NFT to share within the Pass the Baton community and beyond.
      </div>
      <div className="avatar__colors">
        <AvatarColors onChange={onColorChange} colors={colorList} />
      </div>
      {isSaving ? <Loader /> :
        <button className="ptb__button" style={{marginTop:50}} onClick={save}>Save PFP</button>
      }
      {claimData && <div style={{margin: '20px 0'}}>
        <button className="ptb__button" onClick={mint}>Mint PFP</button>
      </div>}
    </div>
    { txnId && <PendingTransactionModal
      txnId={txnId}
      isOpen={Boolean(showPending)}
      onClose={() => setShowPending(false)}
    /> }
  </div>

  return error ? renderError() : renderBaton();
}


const AvatarColors = ({onChange, colors}) => {
  const options = ['hair', 'skin', 'shirt'];
  const [ expanded, setExpanded ] = useState(0);
  const onColorChange = (option, hex) => {
    onChange(option, hex);
  }

  const onToggle = index => {
    if (index === expanded) {
      setExpanded(null);
    }
    else {
      setExpanded(index);
    }
  }

  return <>
    {options.map((option, index) =>
      <AvatarColorPicker
        key={option}
        option={option}
        onChange={onColorChange}
        onToggle={() => onToggle(index)}
        colors={colors}
        expanded={index === expanded}
      />
    )}
  </>
}

const AvatarColorPicker = props => {
  const { option, colors, onChange, onToggle, expanded } = props;
  return <div className={`avatar-colors ${expanded ? 'avatar-colors--expanded' : ''}`}>
    <div className="avatar-colors__header" onClick={onToggle}>
      <img className="avatar-colors__plus" src={plusIcon}/>
      <img className="avatar-colors__minus" src={minusIcon}/>
      {option} color
    </div>
    <div className="avatar-colors__palette">
      {colors.map((hex, idx)=>
        <div
          key={option+idx}
          className="avatar-colors__square"
          style={{backgroundColor: hex}}
          onClick={() => onChange(option, hex)}
        ></div>
      )}
    </div>
  </div>
}

export default BatonAvatar;