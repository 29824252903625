const { ethers } = require("ethers");
const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");

export const merkleLeaf = (blob) =>
  ethers.utils.solidityKeccak256(
    ["address", "uint256"],
    [blob.address, blob.amount]
  );

export const merkleRootFromAllowlist = (allowlist) => {
  const merkleLeaves = allowlist.map((elem) => merkleLeaf(elem));
  const merkleTree = new MerkleTree(merkleLeaves, keccak256, { sort: true });
  const merkleRoot = merkleTree.getHexRoot();
  return merkleRoot;
};

export const merkleProofFor = (allowlist, entry) => {
  const merkleLeaves = allowlist.map((elem) => merkleLeaf(elem));
  const merkleTree = new MerkleTree(merkleLeaves, keccak256, { sort: true });
  const proof = merkleTree.getHexProof(merkleLeaf(entry));
  return proof;
};