import { useEffect } from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import { getEtherscanUrl } from 'components/Constants';
import { useEthers } from '@usedapp/core';
import { shortenString } from 'shared/utils';

const PendingTransactionModal = (props) => {
  const { chainId } = useEthers();
  const { txnId, isInline, isOpen, onClose } = props;
  const [txnUrl, setTxnUrl] = useState();

  useEffect(() => {
    if (!chainId || !txnId) { return }
    let url = getEtherscanUrl(chainId);
    if (url) {
      url += '/tx/' + txnId;
      setTxnUrl(url);
    }
  }, [chainId, txnId]);

  const handleClose = () => {
    if (onClose instanceof Function) {
      onClose();
    }
  }

  const renderContent = () => {
    return (
      <div className="modal__centered">
        <div className="modal__content">
          <div className="modal__title">Transaction Pending...</div>
          <div className="modal__link">
            {
              txnUrl ?
                <a href={txnUrl} rel="noreferrer" target="_blank">
                  {shortenString(txnId, 14)}
                </a> :
                null
            }
          </div>
        </div>
      </div>
    )
  }

  const renderModal = () => {
    return (
      <Modal
        className="modal pending-transaction"
        overlayClassName="modal__overlay"
        isOpen={isOpen}
        onRequestClose={handleClose}
      >
        <div className="modal__close" onClick={handleClose} />
        {
          renderContent()
        }
      </Modal>
    )
  }

  return (
    <>
      {
        isInline ?
          renderContent()
          :
          renderModal()
      }
    </>
  )
}

export default PendingTransactionModal;