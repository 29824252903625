import { useEffect, useState } from 'react';
import getFaq from 'shared/faq';
import 'css/faq.scss';

const FAQ = () => {
  const [faq, setFaq] = useState([]);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    setFaq(getFaq());
  }, []);

  const onClick = (idx) => {
    setSelected(prevState => ({
      ...prevState,
      [idx]: !prevState[idx]
    }))
  }

  return (
    <div className="faq">
      <div className="faq__title">New to NFT?</div>
      <div className="faq__description">
        We’ve got you covered! Here are some of the ways to think about this new technology that we have found especially helpful.
      </div>
      <div className="faq__items">

        {
          faq.map((item, idx) =>
            <div
              key={idx}
              className={`faq__item ${selected[idx] ? 'selected' : ''}`}
              onClick={() => onClick(idx)}
            >
              <div className="faq__question">
                {item.question}
                {
                  item.helper
                    ? <div className="faq__helper">{item.helper}</div>
                    : null
                }
              </div>
              <div className="faq__answer">{item.answer}</div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default FAQ;