import { useEffect, useState } from 'react';
import { useBatons } from 'hooks/useBatons';
import { teamNames } from 'shared/teams.json';
import Modal from 'react-modal';
import PendingTransactionModal from 'components/PendingTransactionModal';
import { teams, organizations } from 'shared/teams.json';
import { ethers } from 'ethers';

const DonateModal = (props) => {
  const signer = useBatons();
  const { baton, batons, isOpen, onClose, onDonate } = props;
  const [members, setMembers] = useState([]);
  const [hasDonated, setHasDonated] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [buttonTitle, setButtonTitle] = useState()
  const [donationAmount, setDonationAmount] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [txnId, setTxnId] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    (async () => {
      if (!batons || !baton || !isOpen) { return }

      const charityGroup = teamNames.indexOf(baton.team);
      if (typeof charityGroup === 'number') {
        let members = [];
        if (charityGroup < teams.length) {
          const team = teams[charityGroup];
          members = team.map((member) => {
            const organization = organizations.find(item => item[0] === member);
            return {
              organization: organization && organization[1],
              member: member
            }
          });
        } else if (charityGroup === 4) {
          members = teams.map((team) => {
            return team.map((member) => {
              const organization = organizations.find(item => item[0] === member);
              return {
                organization: organization && organization[1],
                member: member
              }
            })
          }).flat();
        }
        setMembers(members);
      }
    })();
  }, [batons, baton, isOpen]);

  useEffect(() => {
    if (!baton) { return }
    const title = hasDonated
      ? 'Successfully donated ' + donationAmount + ' ETH'
      : 'Donate with Baton #' + baton.token_id;
    const description = hasDonated
      ? 'Your donation moved your baton closer to its contribution goals and will support the following organizations:'
      : 'Your donation will support the following organizations:';
    const buttonTitle = hasDonated
      ? 'Donate More'
      : 'Donate with Baton #' + baton.token_id;
    setTitle(title);
    setDescription(description);
    setButtonTitle(buttonTitle);
  }, [hasDonated, donationAmount, baton]);

  const handleChangeDonation = (e) => {
    const { value } = e.target;
    const regex = /^([0-9]*([.][0-9]*)?|[.][0-9]+)$/;
    if (value && regex.test(value)) {
      setDonationAmount(value);
    } else if (!value) {
      setDonationAmount(null);
    }
  }

  const handleClose = () => {
    setDonationAmount(null);
    setHasDonated(false);
    setTxnId(null);
    setIsSubmitting(false);
    setMembers([]);
    setError(null);

    if (onClose instanceof Function) {
      onClose()
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    if (hasDonated) {
      setDonationAmount(null);
      setHasDonated(false);
      setTxnId(null);
    } else if (donationAmount) {
      setIsSubmitting(true);
      const eth = ethers.utils.parseEther(donationAmount);
      try {
        const txn = await signer.donate(baton.token_id, { value: eth });
        if (txn.hash) {
          setTxnId(txn.hash);
        }
        await txn.wait();
        setIsSubmitting(false);
        setHasDonated(true);
        if (onDonate instanceof Function) {
          onDonate();
        }
      } catch (error) {
        if (error.toString().indexOf("insufficient funds") > -1) {
          setError("Insufficient funds");
        }
        setIsSubmitting(false);
      }
    }
  }

  return (
    <Modal
      className={`modal donate ${hasDonated ? 'donated' : ''} ${members.length > 10 ? 'scrollable' : ''}`}
      overlayClassName="modal__overlay"
      isOpen={isOpen}
      onRequestClose={handleClose}
    >
      <div className="modal__close" onClick={handleClose} />
      {
        isSubmitting && txnId ? (
          <PendingTransactionModal
            isInline={true}
            txnId={txnId}
          />
        ) : (
          <form className="form" onSubmit={handleSubmit}>
            <div className="modal__title">{title}</div>
            <div className="modal__description">{description}</div>
            <div className="modal__list">
              <div className="modal__list-inner">
                {
                  members
                    ?
                    members.map((member) =>
                      <div className="modal__list-item" key={`member-${member.member}`}>
                        {
                          member.organization
                            ? <span className="donate-org">{member.organization}</span>
                            : null
                        }
                        <span className="donate-member">{member.member}</span>
                      </div>
                    )
                    : null
                }
              </div>
            </div>

            {
              hasDonated ? null :
                <div className="form__row donation">
                  <input
                    type="text"
                    name="donation"
                    placeholder="DONATION AMOUNT"
                    value={donationAmount || ''}
                    onChange={handleChangeDonation}
                  />
                  <span className="helperText">ETH</span>
                </div>
            }
            <button className="ptb__button" disabled={isSubmitting}>{buttonTitle}</button>
            {error && <span className="modal__error">{error}</span>}
          </form>
        )
      }
    </Modal>
  )
}

export default DonateModal;