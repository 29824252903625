import { useEthers } from '@usedapp/core';
import { injectedConnector, walletLinkConnector, walletConnectConnector } from 'components/Connectors';
import Modal from 'react-modal';

import metamaskIcon from 'assets/images/metamask_icon.png';
import walletLinkIcon from 'assets/images/walletlink_icon.png';
import walletConnectIcon from 'assets/images/walletconnect_icon.png';
import { useEffect } from 'react';

const ConnectModal = (props) => {
  const { isOpen, onClose } = props;
  const { activate, account, deactivate, error } = useEthers();

  const handleClose = () => {
    if (onClose instanceof Function) {
      onClose();
    }
  }

  const handleConnect = (connector) => {
    if (connector) {
      console.log(connector)
      activate(connector);
      handleClose();
    }
  }
  useEffect(() => {
    if (error) {
      console.log("UseEffect: error is %s", error)
    }
  }, [error])

  const handleDisconnect = () => {
    deactivate();
    handleClose();
  }

  return (
    <Modal
      className="modal connect"
      overlayClassName="modal__overlay"
      isOpen={isOpen}
      onRequestClose={handleClose}
    >
      <div className="modal__close" onClick={handleClose} />
      <div className="modal__title">
        { account ? 'Disconnect' : 'Connect' } Your Wallet
      </div>
    
      <div className="modal__buttons">
        {
          account ? (
            <button
              onClick={handleDisconnect}
            >
              Disconnect
            </button>
          ) : (
            <>
              <button
                onClick={() => handleConnect(injectedConnector)}
              >
                MetaMask
                <img src={metamaskIcon} alt="MetaMask" />
              </button>
              <button
                onClick={() => handleConnect(walletLinkConnector)}
              >
                WalletLink
                <img src={walletLinkIcon} alt="WalletLink" />
              </button>
              <button
                onClick={() => handleConnect(walletConnectConnector)}
              >
                WalletConnect
                <img src={walletConnectIcon} alt="WalletConnect" />
              </button>
            </>
          )
        }
      </div>
    </Modal>
  )
}

export default ConnectModal;