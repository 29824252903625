import { useEffect, useState, useCallback } from 'react';
import { usePublicBatons } from 'hooks/useBatons';
import { useEthers } from '@usedapp/core';
import { useParams } from "react-router-dom";
import { getContractAddress } from 'components/Constants';
import { formatUnits } from '@ethersproject/units';
import { Link } from 'react-router-dom';
import ProgressBar from 'components/ProgressBar';
import DonateModal from 'components/DonateModal';
import { normalizeBaton, getIpfsGatewayUrl } from 'shared/utils';
import { getOpenSeaUrl } from 'components/Constants';
import 'css/common.scss';
import 'css/details.scss';

const evolutionStates = ['Common', 'Bronze', 'Silver', 'Gold', 'Black'];

const BatonDetails = () => {
  const batons = usePublicBatons();
  const { account, chainId } = useEthers()
  const { tokenId } = useParams();
  const [batonData, setBatonData] = useState({});
  const [isOwner, setIsOwner] = useState(false);
  const [donationData, setDonationData] = useState({});
  const [donateModalIsOpen, setDonateModalIsOpen] = useState(false);
  const [error, setError] = useState();

  const getBatonData = useCallback(async () => {
    if (!batons) { return }

    const path = await batons.tokenURI(tokenId);
    const response = await fetch(getIpfsGatewayUrl(path));
    let batonData = await response.json();
    const charityGroup = await batons.charityGroupForToken(tokenId);
    if (charityGroup) {
      batonData.charity_group = charityGroup.toNumber();
    }
    batonData.token_id = tokenId;
    batonData = normalizeBaton(batonData, tokenId);
    setBatonData(batonData);

    const donations = {};
    const batonDonation = await batons.getBatonDonation(tokenId);

    if (batonData.evolution === 'Black') {
      batonData.evolution_level = 4;
      batonData.current_evolution = batonData.evolution.toLowerCase();
      batonData.progress_title = 'Fully evolved';
    } else {
      const evolutionLevel = await batons.getEvolutionLevel(tokenId);
      if (evolutionLevel) {
        const level = parseInt(evolutionLevel);
        batonData.evolution_level = level;

        if (level < evolutionStates.length) {
          batonData.current_evolution = evolutionStates[level].toLowerCase();
        }

        if (level + 1 < evolutionStates.length - 1) {
          batonData.next_evolution = evolutionStates[level + 1].toLowerCase();
        }

        if (batonData.current_evolution && batonData.next_evolution) {
          batonData.progress_title = `Evolution progress from ${batonData.current_evolution} to ${batonData.next_evolution}`;
        } else if (level === 3) {
          batonData.progress_title = 'Evolution progress from Silver to Gold';
        }
      }
    }

    const openSeaUrl = getOpenSeaUrl(chainId);
    if (openSeaUrl) {
      batonData.opensea_url = openSeaUrl + '/assets/' + getContractAddress() + '/' + tokenId;
    }

    setBatonData(prevState => ({
      ...prevState,
      ...batonData
    }));

    let batonUpgradeEth;
    switch (batonData.evolution_level) {
      case 0:
        batonUpgradeEth = await batons.BRONZE_UPGRADE();
        break;
      case 1:
        batonUpgradeEth = await batons.SILVER_UPGRADE();
        break;
      default:
        batonUpgradeEth = await batons.GOLD_UPGRADE();
        break;
    }

    if (batonUpgradeEth) {
      donations.batonEth = formatUnits(batonDonation);
      donations.batonUpgradeEth = parseFloat(formatUnits(batonUpgradeEth));
      donations.batonPercentage = Math.min(parseFloat(donations.batonEth / donations.batonUpgradeEth), 1);
    }

    setDonationData(prevState => ({
      ...prevState,
      ...donations
    }));
  }, [batons, tokenId, chainId]);

  useEffect(() => {
    if (!batons) { return }

    (async () => {
      try {
        if (account) {
          const batonOwner = await batons.ownerOf(tokenId);
          setIsOwner(batonOwner === account);
        }
        getBatonData();
      } catch (err) {
        if (err.toString().indexOf("owner query for nonexistent token") > -1) {
          setError('This token does not exist.');
        }
      }

    })();
  }, [batons, tokenId, account, getBatonData]);

  useEffect(() => {
    if (!account) {
      setIsOwner(false);
    }
  }, [account]);

  const handleOnDonation = async (e) => {
    getBatonData();
  }

  const renderError = () => {
    return (
      <div className="details error">
        <div className="details__title">{error}</div>
        <Link className="ptb__button" to="/gallery">Visit the Baton Gallery</Link>
      </div>
    )
  }

  const renderBaton = () => {
    return (
      <div className="details">
        <div className="details__col-left">
          <img src={getIpfsGatewayUrl(batonData.image)} alt="" />
        </div>
        <div className="details__col-right">
          <h1 className="details__title">
            Baton #{tokenId}
          </h1>
          <div className="details__attributes">
            <div className="details__opensea">
              <a className="ptb__button" href={batonData.opensea_url} rel="noreferrer" target="_blank">View on OpenSea</a>
            </div>

            <BatonAttribute label="Evolution" value={batonData.current_evolution} />
            <BatonAttribute label="Grip Tape" value={batonData.tape_color} />
            <BatonAttribute label="Material" value={batonData.baton_color} />
            <BatonAttribute label="Legacy Team" value={batonData.team} />

          </div>
          <div className="details__subtitle">
            Evolution Progress
          </div>
          <div className={`details__evolution selected-${batonData.current_evolution}`}>
            {
              evolutionStates.map((state, i) =>
                <span key={`state-${i}`}>{state}</span>
              )
            }
          </div>
          <div className="details__description">
            Batons evolve every time one of the donation milestones is met.
          </div>
          <ProgressBar
            className="details__progress-bar"
            progressClassName={`level${batonData.evolution_level}`}
            progress={donationData.batonPercentage || 0}
            title={batonData.progress_title}
            subtitle={`${donationData.batonUpgradeEth ? donationData.batonUpgradeEth + ' ETH' : ''}`}
            label={`${donationData.batonEth || 0} ETH`}
          />
          {
            batonData.current_evolution === 'gold' && donationData.batonEth >= donationData.batonUpgradeEth
              ?
              <div className="details__evolve-black">
                To evolve your baton to the all-black Legacy baton, you must collect and then burn 4 Gold batons—one from each Legacy Team. That process creates a very rare, matte black-on-black-on-black baton featuring the names of all 24 Legacy Team members. Only 1,968 Legacy batons can ever be minted.
                <a href="/evolutions">To learn more</a>
              </div>
              : null
          }
          {
            isOwner ?  <>
              <button
                className="ptb__button details__donate-button"
                onClick={(e) => setDonateModalIsOpen(true)}>
                Donate with Baton #{tokenId}
              </button>
              &nbsp; &nbsp;
              <Link className="ptb__button" to={`/avatar/${tokenId}`}>Build Profile</Link>
            </> : null
          }
        </div>
        <DonateModal
          baton={batonData}
          batons={batons}
          isOpen={donateModalIsOpen}
          onClose={() => setDonateModalIsOpen(false)}
          onDonate={handleOnDonation}
        />
      </div>
    )
  }

  return (
    <>
      {
        error
          ? renderError()
          : renderBaton()
      }
    </>
  )
}

const BatonAttribute = ({ label, value }) =>
  <div className="details__attr">
    <div className="details__attr-label">
      {label}
    </div>
    <div className="details__attr-value">
      {value}
    </div>
  </div>

export default BatonDetails;