import { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { DAppProvider, ChainId } from '@usedapp/core';
import { getAlchemyUrl } from 'components/Constants';

import TopNav from 'components/TopNav';
import Homepage from 'pages/Homepage';
import Partners from 'pages/Partners';
import GlennTommie from 'pages/GlennTommie';
import Mint from 'pages/Mint';
import User from 'pages/User';
import Gallery from 'pages/Gallery';
import BatonDetails from 'pages/BatonDetails';
import BatonAvatar from 'pages/BatonAvatar';
import Drawing from 'pages/Drawing';
import DevTools from 'pages/DevTools';
import Evolutions from 'pages/Evolutions';
import FAQ from 'pages/FAQ';
import Terms from 'pages/Terms';
import License from 'pages/License';
import FWB from 'pages/FWB';
import OGCrystals from 'pages/OGCrystals';
import Puma from 'pages/Puma';
import SeedClub from 'pages/SeedClub';
import TheCultivist from 'pages/TheCultivist';
import Pace from 'pages/Pace';
import RocketFactory from 'pages/RocketFactory';
import Greenlist from 'pages/Greenlist';
import Wasserman from './Wasserman';
import AndrewWang from './AndrewWang';
import AdamBomb from "./AdamBomb"
import Hashed from "./Hashed"
import HashedFriends from "./HashedFriends"
import Sandbox from "./Sandbox"

const config = {
  readOnlyChainId: ChainId.Mainnet,
  readOnlyUrls: {
    [ChainId.Mainnet]: getAlchemyUrl(ChainId.Mainnet),
    [ChainId.Rinkeby]: getAlchemyUrl(ChainId.Rinkeby)
  },
};

const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    if (location) {
      window.gtag('set', 'page_path', location.pathname);
      window.gtag('event', 'page_view');
    }
  }, [location]);

  return (
    <Switch>
      <Route exact path="/">
        <DAppProvider config={config}>
          <TopNav />
          <Homepage />
        </DAppProvider>
      </Route>
      <Route exact path="/partners">
        <Partners />
      </Route>
      <Route exact path="/glenn-tommie">
        <GlennTommie />
      </Route>
      <Route exact path="/mint">
        <DAppProvider config={config}>
          <TopNav selectedIndex={0} />
          <Mint />
        </DAppProvider>
      </Route>
      <Route exact path="/presale">
        <DAppProvider config={config}>
          <TopNav />
          <Mint isPresale={true} />
        </DAppProvider>
      </Route>
      <Route exact path="/advisor">
        <DAppProvider config={config}>
          <TopNav />
          <Mint isPresale={true} priority="advisor" />
        </DAppProvider>
      </Route>
      <Route exact path="/account">
        <DAppProvider config={config}>
          <TopNav selectedIndex={4} />
          <User />
        </DAppProvider>
      </Route>
      <Route exact path="/gallery">
        <DAppProvider config={config}>
          <TopNav selectedIndex={3} />
          <Gallery />
        </DAppProvider>
      </Route>
      <Route exact path="/faq">
        <DAppProvider config={config}>
          <TopNav selectedIndex={1} />
          <FAQ />
        </DAppProvider>
      </Route>
      <Route exact path="/evolutions">
        <DAppProvider config={config}>
          <TopNav selectedIndex={2} />
          <Evolutions />
        </DAppProvider>
      </Route>
      <Route exact path="/baton/:tokenId/:evolutionLevel?">
        <DAppProvider config={config}>
          <TopNav />
          <BatonDetails />
        </DAppProvider>
      </Route>
      <Route exact path="/avatar/:tokenId">
        <DAppProvider config={config}>
          <TopNav />
          <BatonAvatar />
        </DAppProvider>
      </Route>
      <Route exact path="/drawing">
        <DAppProvider config={config}>
          <TopNav />
          <Drawing />
        </DAppProvider>
      </Route>
      <Route exact path="/terms">
        <TopNav />
        <Terms />
      </Route>
      <Route exact path="/license">
        <TopNav />
        <License />
      </Route>
      {/^localhost|^dev/.test(window.location.hostname) &&
        <Route exact path="/devtools">
          <DAppProvider config={config}>
            <DevTools />
          </DAppProvider>
        </Route>
      }
      <Route exact path="/fwb">
        <FWB />
      </Route>
      <Route exact path="/ogcrystals">
        <OGCrystals />
      </Route>
      <Route exact path="/puma">
        <Puma />
      </Route>
      <Route exact path="/seedclub">
        <SeedClub />
      </Route>
      <Route exact path="/the-cultivist">
        <TheCultivist />
      </Route>
      <Route exact path="/pace">
        <Pace />
      </Route>
      <Route exact path="/rocket-factory">
        <RocketFactory />
      </Route>
      <Route exact path="/greenlist">
        <Greenlist />
      </Route>
      <Route exact path="/wasserman">
        <Wasserman />
      </Route>
      <Route exact path="/andrewwang">
        <AndrewWang />
      </Route>
      <Route exact path="/adam-bomb">
        <AdamBomb />
      </Route>
      <Route exact path="/hashed">
        <Hashed />
      </Route>
      <Route exact path="/hashed-friends">
        <HashedFriends />
      </Route>
      <Route exact path="/sandbox">
        <Sandbox />
      </Route>
      <Route path="**">
        <Redirect to="/" />
      </Route>
    </Switch>
  )
}

export default Layout;