import { useState, useEffect, useRef } from 'react';
import { ethers } from 'ethers';
import { useEthers } from '@usedapp/core';
import { getContractAddress, contractType } from 'components/Constants';
import batonsAbi from 'abi/Batons.json';
import drawingAbi from 'abi/Drawing.json';
import profileAbi from 'abi/Profile.json';

const getAbi = type => {
  switch (type) {
    case contractType.drawing: return drawingAbi;
    case contractType.profile: return profileAbi;
    default: return batonsAbi;
  }
}

export function useContract(type) {
  const [contract, setContract] = useState(null);

  const { active, account, library, chainId } = useEthers();

  useEffect(() => {
    if (!active || !library || !account || !chainId) {
      // All dependencies need to be valid
      return;
    }

    let contract = new ethers.Contract(
      getContractAddress(chainId, type),
      getAbi(type)
    );

    setContract(
      contract.connect(library.getSigner())
    )

  }, [library, account, active, chainId]);

  return contract;
}

export function useUnsignedContract(type) {
  const [contract, setContract] = useState(null);
  const { library, chainId } = useEthers();
  const activeLib = useRef();

  useEffect(() => {
    if (!library || activeLib.current || !chainId) { return }
    activeLib.current = library;

    const batons = new ethers.Contract(
      getContractAddress(chainId, type),
      getAbi(type)
    );

    setContract(
      batons.connect(activeLib.current)
    );
  }, [library, chainId]);

  return contract;
}