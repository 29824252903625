import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from 'components/splash/Header';
import 'css/common.scss';
import 'css/glenntommie.scss';

import coverImage from 'assets/images/misc/glenn-and-tommie.jpeg';
import glennImage from 'assets/images/misc/glenn.jpg';
import tommieImage from 'assets/images/misc/tommie.jpg';

const GlennTommie = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Header />
      <div className="glenn-tommie">
        <div className="glenn-tommie__container">
          <h1 className="glenn-tommie__title">About Glenn Kaino and Tommie Smith’s Collaboration</h1>
        </div>
        <div className="glenn-tommie__cover">
          <img className="glenn-tommie__cover__image" src={coverImage} alt="Tommie statue"/>
        </div>
        <div className="glenn-tommie__container">
          <div className="glenn-tommie__container__column">
            <p className="glenn-tommie__text">
              Artist Glenn Kaino has been collaborating with Tommie Smith for nearly a decade, creating
              objects, drawings, and films inspired by Smith’s stories and their shared desire to instigate
              progress towards universal human rights. Pass the Baton started in the Oval Office. Glenn Kaino
              and Tommie Smith were invited into the White House by President Barack Obama toward the end of
              his second term. Not wanting to arrive empty handed, they made a drawing of Tommie running a
              relay, passing a baton to the final runner. On the back, Tommie added the inscription: “I can
              only imagine the length of passage, but most importantly the baton was not dropped.” Shortly
              thereafter President Obama began using that phrase to describe the long journey of change.
            </p>
          </div>
          <div className="glenn-tommie__container__column">
            <p className="glenn-tommie__text">
              The baton NFT is based on the second most important item in Tommie’s collection of memories,
              after his Gold Medal, and an object of great personal significance to Tommie—the baton he used
              in four record-breaking races, including with the legendary 1966 1600m relay team, which was the
              first to break the 3:00 barrier and the first group of American men to break the world record.
              Tommie personalized the baton himself with labels and tape to record these historic
              achievements, and it carries the memory of the teamwork and determination essential both to his
              success on the track and to the greater project of human rights. The Legacy Team members
              represented on each baton form an intergenerational relay team and create a continuum, literally
              passing the baton and carrying forward Tommie’s spirit forward for new generations.
            </p>
          </div>
        </div>
        <div className="glenn-tommie__individuals glenn-tommie__container">
          <div className="glenn-tommie__person">
            <div className="glenn-tommie__person__header">
              <h1 className="glenn-tommie__person__name">
                Glenn
                <br />
                Kaino
              </h1>
              <img className="glenn-tommie__person__picture" src={glennImage} alt="Glenn Kaino headshot"/>
            </div>
            <p className="glenn-tommie__text">
              Glenn Kaino is a conceptual artist, Emmy-Award-winning creator, and digital pioneer whose work
              spans an extraordinary range of media and creative activity. As a visual artist, he was chosen
              by the U.S. Department of State to represent the country in the 13th Cairo Biennale and was
              included in the 2004 Whitney Biennial, the 12th Lyon Biennial, and Prospect.3 in New Orleans. He
              has had solo exhibitions at the High Museum of Atlanta, the Modern Art Museum of Fort Worth, The
              Andy Warhol Museum, and the Whitney Museum of American Art, among others.
            </p>
            <p className="glenn-tommie__text">
              Kaino is co-director of the acclaimed documentary With Drawn Arms and producer of the hit film
              and off-Broadway production of In &amp; Of Itself. In 2016 Kaino co-founded VISIBILITY, a long-term
              collaboration with actor and activist Jesse Williams. VISIBILITY builds on decades of
              entrepreneurial work in technology, as Kaino launched the first website for artists of color and
              made the first legal music streaming service as the Chief Creative Officer of Napster 2.0, among
              scores of other seminal projects.
            </p>
          </div>
          <div className="glenn-tommie__person">
            <div className="glenn-tommie__person__header glenn-tommie__person__header--inverse">
              <h1 className="glenn-tommie__person__name">
                Tommie
                <br />
                Smith
              </h1>
              <img className="glenn-tommie__person__picture" src={tommieImage} alt="Tommie Smith headshot" />
            </div>
            <p className="glenn-tommie__text">
              Tommie Smith is the only man in the history of track and field to hold eleven world records
              simultaneously. During the historic 19th Olympiad in Mexico City in the summer of 1968, Smith
              ran with an injury and shattered the world record in the 200-meter men’s race to win the gold
              medal. During the award ceremony, Smith raised his fist to give a salute and then bowed his head
              in prayer, a silent protest and stand for liberation, unity, and human rights. This courageous,
              unexpected worldwide event propelled Smith into the spotlight as a human rights spokesman,
              activist, and symbol of African American pride at home and abroad. Cheered by some, jeered by
              others, and ignored by many more, Smith made a commitment to dedicate his life, even at great
              personal risk, to champion the cause of oppressed people. Smith has enjoyed a distinguished
              career as a coach, educator, and activist, and was awarded an Honorary Doctorate Degree (LHD)
              from his alma mater, San Jose State University in May 2005. He is the founder of the Tommie
              Smith Youth Initiative, dedicated to nurturing the health, fitness, and educational development
              of youth, particularly marginalized children.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default GlennTommie;
