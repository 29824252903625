import { useEffect, useRef, useState, useContext } from 'react';
import { teams } from 'shared/teams.json';
import ScrollHelperContext from 'shared/ScrollHelperContext';
import Marquee from "react-fast-marquee";
import 'css/splash/footer.scss';

import twitterIcon from 'assets/images/logos/twitter.png';
import instagramIcon from 'assets/images/logos/instagram.png';
import openseaIcon from 'assets/images/logos/opensea.png';
import { Link } from 'react-router-dom';

const marqueeHeight = 80;

const Footer = () => {
  const el = useRef();
  const [ isInline, setIsInline ] = useState(false);
  const scrollHelper = useContext(ScrollHelperContext);
 
  useEffect(() => {
    if (!el.current || !scrollHelper) return;

    scrollHelper.add(el.current, {
      startY: data => data.top - data.windowHeight + marqueeHeight,
      endY: data => data.bottom,
      handler: value => {
        setIsInline(value > 0)
      }
    });
  }, [el, scrollHelper]);

  return <div className="splash-footer" ref={el}>
    <div className="splash-footer__marquee-container">
      <div className={`splash-footer__marquee ${isInline ? 'splash-footer__marquee--inline' : ''}`}>
        <div className="splash-footer__supported-by">Supported by</div>
        <div className="splash-footer__cover-right" />
        <Marquee gradient={false} speed={30}>
          <div className="splash-footer__names">
            {teams.map((list, index) => 
              <p key={index}>
                {list.join(' • ')}
              </p>
            )}
          </div>
        </Marquee>
      </div>
    </div>
    <div className="splash-footer__divider"></div>
    <div className="splash-footer__row">
      <div className="splash-footer__col">
        <div className="splash-footer__title">
          Pass The Baton
        </div>
        <ul className="splash-footer__list">
          <li><a href="https://baton.art">baton.art</a></li>
          <li><a href="https://tsyinitiative.org/web/" target="_blank" rel="noreferrer">tsyinitiative.org</a></li>
          <li><Link to="/terms">Terms of Service</Link></li>
        </ul>
      </div>
      <div className="splash-footer__col">
        <div className="splash-footer__title">
          Follow Us
        </div>
        <ul>
          <li className="splash-footer__social">
            <a href="https://twitter.com/passthebaton">
              <img alt="Twitter Logo" src={twitterIcon} />
            </a>
            <a href="https://www.instagram.com/passthebaton/">
              <img alt="Instagram Logo" src={instagramIcon} />
            </a>
            <a href="https://opensea.io/collection/pass-the-baton">
              <img alt="OpenSea Logo" src={openseaIcon} />
            </a>
          </li>
          <li className="splash-footer__copyright">All Rights Reserved &copy; 2021</li>
          <li className="splash-footer__list--item"><Link to="/license">User License</Link></li>
        </ul>
      </div>
    </div>
  </div>
}

export default Footer;