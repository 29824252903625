import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ConnectModal from 'components/ConnectModal';
import 'css/splash/header.scss';
import { useEthers } from '@usedapp/core';
import { ethers } from 'ethers';
import { formatUnits } from '@ethersproject/units';
import { shortenString } from 'shared/utils';
import { useIsMobile } from 'shared/hooks';

const TopNav = props => {
  // const [menuY, setMenuY] = useState(-100);
  const { account } = useEthers();
  const [balance, setBalance] = useState(0);
  const [connectModalIsOpen, setConnectModalIsOpen] = useState(false);
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);
  const isMobile = useIsMobile();
  const provider = useRef();

  const el = useRef();
  const { selectedIndex } = props;

  const links = [
    { name: "Mint", link: "/mint" },
    { name: "FAQ", link: "/faq" },
    { name: "How Batons Evolve", link: "/evolutions" },
    { name: "The Baton Gallery", link: "/gallery" },
    { name: "Your Batons", link: "/account" }
  ];

  useEffect(() => {
    if (!account || !window.ethereum) { return }
    if (!provider.current) {
      provider.current = new ethers.providers.Web3Provider(window.ethereum, 'any');
    }
    (async () => {
      const balance = await provider.current.getBalance(account);
      if (balance) {
        setBalance(parseFloat(formatUnits(balance)).toFixed(2));
      }
    })();
  }, [account]);

  useEffect(() => {
    if (!isMobile) {
      setMobileNavIsOpen(false);
    }
  }, [isMobile])

  return (
    <div
      className={`splash-header ${props.className || ''} ${mobileNavIsOpen ? 'mobile-nav--active' : ''}`}
      ref={el}
      style={{
        transform: `translate3d(0, ${0}%, 0)`,
        opacity: 1,
      }}
    >
      <div className="splash-header__title">
        <Link to="/">Pass The Baton</Link>
      </div>
      <div className="splash-header__links">
        {
          links.map((link, i) =>
            <Link
              key={`nav-link-${i}`}
              className={selectedIndex === i ? 'selected' : ''}
              to={link.link}
              onClick={() => setMobileNavIsOpen(false)}
            >
              {link.name}
            </Link>
          )
        }
      </div>
      <div className={`splash-header__button ${account ? 'connected' : ''}`}>
        <button
          className="ptb__button"
          onClick={() => setConnectModalIsOpen(true)}
        >
          {
            account ?
              <>
                <span className="splash-header__balance">
                  {balance}ETH
                </span>
                <span className="splash-header__account">
                  {shortenString(account, 14)}
                </span>
              </> :
              <>Connect your wallet</>
          }
        </button>
      </div>
      <ConnectModal
        isOpen={connectModalIsOpen}
        onClose={() => setConnectModalIsOpen(false)}
      />
      <div
        className="splash-header__menu-button"
        onClick={() => setMobileNavIsOpen(!mobileNavIsOpen)}
      />
    </div>
  );
};

export default TopNav;
