import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { useIsMobile } from 'shared/hooks';

import Header from 'components/splash/Header';
import SpinningBatons from 'components/SpinningBatons';
import 'css/fwb.scss';

const FWB = () => {
  const location = useLocation();
  const [inputValue, setInputValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [response, setResponse] = useState(null);
  const [isSuccess, setIsSuccess] = useState(null);

  const isMobile = useIsMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const onInputChange = (e) => {
  	setInputValue(e.target.value);
  	setResponse(null);
  }

  const onEmailChange = (e) => {
  	setEmailValue(e.target.value);
  	setResponse(null);
  }

  const onFormSubmit = (e) => {
  	e.preventDefault();

  	// console.log('OnSubmit', inputValue, emailValue);

  	// axios.get(`http://localhost:8000/fwb?address=${inputValue}&email=${emailValue}`)
  	axios.get(`https://baton-collab.vercel.app/fwb?address=${inputValue}&email=${emailValue}`)
	  .then(function (response) {
	    console.log(response);
	    setResponse('Your address has been recorded for presale. Minting for the presale runs December 16, 7a-7p pacific here at baton.art and the public sale starts the next day. Join us on Discord to stay up to date on the latest.');
      setIsSuccess('success');
	  })
	  .catch(function (error) {
	    console.log(error);
	    setResponse('oops something went wrong');
      setIsSuccess('error');
	  });

  	// console.log(e, inputValue);
  }

  return (
    <>
		{isMobile ? (
	    		<div className="splash-header__title--mobile">
		        	<Link to="/">Pass The Baton</Link>
		      	</div>
			):(
				<Header className="splash-desktop" />
			)
		}
      <div className="fxx" style={{ background: 'url(/fwb-background.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      	<div className="fxx__content">
          <SpinningBatons />
      		<svg
  		      xmlns="http://www.w3.org/2000/svg"
  		      width="256"
  		      height="89"
  		      fill="none"
  		      viewBox="0 0 256 89"
  		    >
  		      <g fill="#fff" clipPath="url(#clip0_348_2674)">
  		        <path d="M54.545 1.278H0v85.47h11.358V49.075h42.51V38.953h-42.51V11.399h43.185V1.279h.002zM232.572 43.975c12.596-1.238 21.257-8.697 21.257-19.942 0-11.807-7.986-22.755-28.793-22.755h-32.163v85.47h32.163c20.132 0 31.714-7.723 31.714-23.13 0-12.931-8.321-19.08-24.178-19.643zm9.446-18.818c0 9.109-6.298 13.644-16.982 13.644h-20.804V11.399h20.804c10.684 0 16.982 4.761 16.982 13.758zm-37.786 51.47V48.925h21.142c13.159 0 19.568 4.462 19.568 13.908 0 9.334-6.409 13.796-19.568 13.796h-21.142zM161.753.062c-8.612 0-12.202 4.844-12.202 9.51 0 .01.003.019.003.028 0 .12.026.233.031.35.173 4.005 3.451 7.205 7.5 7.205a7.526 7.526 0 007.526-7.474c0-.019.007-.038.007-.06 0-.037-.009-.07-.011-.108-.041-1.176-.188-2.174-.343-2.635 5.921 1.257 10.05 11.843 10.05 25.839 0 24.581-14.423 46.115-30.505 46.115-10.049 0-15.611-5.561-15.611-18.661 0-9.152 1.076-15.253 5.92-43.066 2.153-12.201 2.79-15.83 2.79-15.83h-11.57s-3.6 20.855-4.676 26.776c-5.92 33.733-14.912 51.858-27.813 51.858-8.612 0-13.278-9.152-13.278-21.713 0-17.585 8.855-40.076 30.027-57.48l-.72-.716c-4.844 1.794-9.33 1.195-13.817 1.195-6.64 0-9.151-.898-13.1-.898-7.894 0-10.767 6.281-13.817 16.868l.717.359c2.153-5.743 5.742-6.64 10.408-6.64 2.511 0 5.92.539 9.87.539 1.973 0 4.306-.18 6.459-.54C76.578 27.751 67.545 49.584 67.545 64.3c0 15.43 8.612 23.686 19.02 23.686 11.485 0 23.865-7.895 32.658-39.297-.54 3.768-1.975 12.56-1.975 17.226 0 14.894 7.358 22.071 20.277 22.071 21.712 0 42.348-34.453 42.348-59.752.003-13.279-4.664-28.172-18.12-28.172z"></path>
  		      </g>
  		      <defs>
  		        <clipPath id="clip0_348_2674">
  		          <path fill="#fff" d="M0 0H256V88.202H0z"></path>
  		        </clipPath>
  		      </defs>
  		    </svg>
			  <div className="fxx__success">
				<h2 className="fxx__content__description">Thanks for your support</h2>
				<h4 className="fxx__response">We've reached capacity for this page, but come back for our main sale December 17.<br/>For more opportunities and to get reminded, join us on Discord and Twitter:</h4>
				<a href="https://discord.gg/passthebaton" target="_blank">
				<button className="fxx__response__button">Join our Discord</button>
				</a>
				<a href="https://twitter.com/passthebaton" target="_blank">
				<button className="fxx__response__button">Follow our Twitter</button>
				</a>
			</div>
      	</div>
      </div>
    </>
  );
};

export default FWB;