import { useEffect, useState } from 'react';
import ScrollHelper from 'shared/ScrollHelper';
import { ScrollHelperProvider } from 'shared/ScrollHelperContext';
import Intro from 'components/splash/Intro';
import Donate from 'components/splash/Donate';
import Resources from 'components/splash/Resources';
import How from 'components/splash/How';
import About from 'components/splash/About';
import Footer from 'components/splash/Footer';
import 'css/common.scss';

const Homepage = () => {
  const [ scrollHelper, setScrollHelper ] = useState();

  useEffect(() => {
    const scrollHelper = new ScrollHelper();
    setScrollHelper(scrollHelper);
    return () => scrollHelper.destroy();
  }, []);

  return <ScrollHelperProvider value={scrollHelper}>
    <Intro />
    <Donate />
    <How />
    <Resources />
    <About />
    <Footer />
  </ScrollHelperProvider>
}

export default Homepage;