class ScrollHelper {
  constructor(container) {
    this.params = [];
    this.scrollHandler = this.update.bind(this);
    this.resizeHandler = this.recalculate.bind(this);
    window.addEventListener('scroll', this.scrollHandler);
    window.addEventListener('resize', this.resizeHandler);
    window.addEventListener('load', this.resizeHandler);
  }

  getRectData(el) {
    const rectData = el.getBoundingClientRect();
    const y = window.scrollY;
    return {
      top: rectData.top + y,
      bottom: rectData.bottom + y,
      height: rectData.height,
      windowHeight: window.innerHeight
    }
  }
  
  calculateRatio(startY, endY, scrollY) {
    let ratio = 0;
  
    if (scrollY >= endY) {
      ratio = 1;
    }
    else if (scrollY <= startY) {
      ratio = 0;
    }
    else {
      const delta = endY - startY;
      const normalized = scrollY - startY;
      ratio = normalized / delta;
    }
  
    // Clamp
    return ratio < 0 ? 0 : ratio > 1 ? 1 : ratio;
  }

  calculateValues(el, options) {
    const { startY, endY } = options;
    const rectData = this.getRectData(el);
    options.rectData = rectData;
    options.startYValue = typeof startY === 'function' ? startY(rectData) : startY;
    options.endYValue = typeof endY === 'function' ? endY(rectData) : endY;
  }

  add(el, options) {
    options.el = el;
    this.calculateValues(el, options);
    this.params.push(options);
    this.updateParam(options, window.scrollY);
  }

  update() {
    const scrollY = window.scrollY;
    this.params.forEach(param => this.updateParam(param, scrollY));
  }

  recalculate() {
    const scrollY = window.scrollY;
    this.params.forEach(param => {
      this.calculateValues(param.el, param);
      this.updateParam(param, scrollY);
    });
  }

  updateParam(param, scrollY) {
    const ratio = this.calculateRatio(param.startYValue, param.endYValue, scrollY);
    if (ratio === param.prevRatio) {
      return;
    }
    param.prevRatio = ratio;
    const { startRange, endRange } = param;
    if (startRange !== undefined && endRange !== undefined) {
      const delta = param.endRange - param.startRange;
      const value = param.startRange + (ratio * delta);
      param.handler(value, ratio);
    }
    else {
      param.handler(ratio, ratio);
    }
  }

  destroy() {
    window.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('resize', this.resizeHandler);
    this.params.length = 0;
  }
}


export default ScrollHelper;