import { useEffect, useState } from 'react';
import { getGalleryApiUrl } from 'components/Constants';
import { useMilestones } from 'hooks/useMilestones';
import { formatUnits } from '@ethersproject/units';
import TotalRaised from 'components/gallery/TotalRaised';
import EvolutionFilter from 'components/gallery/EvolutionFilter';
import BatonItem from 'components/gallery/BatonItem';
import UserBatons from 'components/gallery/UserBatons';
import 'css/common.scss';
import 'css/gallery/main.scss';

const Gallery = () => {
  const [ batonList, setBatonList ] = useState([]);
  const [ filters, setFilters ] = useState([]);
  const [ loadMoreUrl, setLoadMoreUrl ] = useState();
  const milestones = useMilestones();

  const handleClickLoadMore = async () => {
    const url = `${getGalleryApiUrl()}/${loadMoreUrl}`;
    const resp = await fetch(url);
    const json = await resp.json();
    setBatonList([...batonList, ...json.items]);
    setLoadMoreUrl(json.next);
  }
  
  useEffect(() => {
    (async () => {
      const url = `${getGalleryApiUrl()}/browse${filters.length ? `?evolution=${filters.join(',')}` : ''}`;
      const resp = await fetch(url);
      const json = await resp.json();
      console.log(json);
      setBatonList(json.items || []);
      setLoadMoreUrl(json.next);
    })();
  }, [filters])

  return <div className="gallery">
    <div className="gallery__top-header">
      <TotalRaised className="gallery__total-raised" />
      <div className="gallery__title">
        The Baton Gallery
      </div>
    </div>
    <div className="gallery__subtitle">
      Showing by evolution progress
    </div>
    <EvolutionFilter className="gallery__filters" onChange={setFilters}/>
    <div className="gallery__header">
      <span></span>
      <span>Baton #</span>
      <span>Legacy Team</span>
      <span>Evolution Progress</span>
      {milestones.length &&
        <span className="gallery__header-evolutions">
          <span><span>Bronze, {formatUnits(milestones[0])} ETH</span></span>
          <span><span>Silver, {formatUnits(milestones[1])} ETH</span></span>
          <span><span>Gold, {formatUnits(milestones[2])} ETH</span></span>
        </span>
      }
    </div>
    <UserBatons milestones={milestones}/>
    <div className="gallery__section-title">
      All Batons
    </div>
    <div className="gallery__list">
      <div className="gallery__batons">
        {batonList.map(baton =>
          <BatonItem
            key={baton.token_id}
            baton={baton}
            milestones={milestones}
            skipUpdate={true}
          />
        )}
      </div>
        {
          loadMoreUrl
            ? <div className="gallery__load-more">
              <button className="ptb__button" onClick={handleClickLoadMore}>Load More</button>
            </div>
            : null
        }
    </div>
  </div>
}

export default Gallery;