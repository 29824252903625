import { useEffect, useState, useRef, useContext } from 'react';
import ScrollHelperContext from 'shared/ScrollHelperContext';
import ScrollFade from 'components/ScrollFade';
import { useIsMobile } from 'shared/hooks';
import 'css/splash/donate.scss';

import topImage from 'assets/images/splash/GKxTS.jpg';
import bottomImage from 'assets/images/splash/Tommie_Obama.jpg';
import batonLoop from 'assets/videos/spinning_cadense_01.mp4';
import batonAnimation from 'assets/images/splash/batons/batons_cadense_01.gif';

const Donate = props => {
  const el = useRef();
  const videoEl = useRef();
  const [photoY, setPhotoY] = useState(200);
  const scrollHelper = useContext(ScrollHelperContext);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!el.current || !scrollHelper) return;

    scrollHelper.add(el.current, {
      startY: data => data.top - data.windowHeight,
      endY: data => data.bottom,
      startRange: 200,
      endRange: 0,
      handler: value => {
        setPhotoY(value);
      }
    });
  }, [el, scrollHelper])

  return <div className="splash-pass" ref={el}>
    <div className="splash-pass__content">
      <ScrollFade className="splash-pass__title">
        Pass to Donate
      </ScrollFade>
      <ScrollFade className="splash-pass__summary">
        <p>
          Pass the Baton is an art project celebrating Tommie Smith’s iconic raised-fist protest for human rights from the podium during the medal ceremony in the 1968 Olympic Games. Inspired by the baton Tommie used in four record-breaking races, 7,872 digitally-rendered baton NFTs will bring Tommie’s salute and message of unity and perseverance to digital space. In partnership with 24 organizations fighting for human rights—Tommie’s Legacy Team—the project will use blockchain technology to drive meaningful change. 
        </p>
      </ScrollFade>
      {isMobile ?
        <img src={batonAnimation} alt="Spinning batons" />
      :
        <video className="splash-pass__baton-loop" ref={videoEl} autoPlay={true} muted loop>
          <source src={batonLoop} type="video/mp4"/>
        </video>
      }
    </div>
    <div className="splash-pass__images">
      <img className="splash-pass__image-top" alt="Glenn Kaino and Tommie Smith" src={topImage} />
      <img className="splash-pass__image-bottom" alt="Tommie Smith and President Obama" src={bottomImage} style={{transform: `translate3d(0,${photoY}px,0)`}} />
    </div>
  </div>
}

export default Donate;