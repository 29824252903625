import { useEffect, useState } from 'react';
import { merkleProofFor } from 'shared/helpers';
import { whitelist } from 'shared/presale.json';
import { advisorlist } from 'shared/advisors.json';
import { parseEther } from '@ethersproject/units';

const MintButton = props => {
  const {
    charityGroup,
    quantity,
    presaleUser,
    advisorUser,
    mintPrice,
    batons,
    priority,
    onMinting
  } = props;

  const [canMint, setCanMint] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState();

  let displayPrice = (parseFloat(mintPrice) * quantity).toFixed(1);
  // User is an advisor if only in the advisor list, OR is in both the advisor and presale list,
  // but priority == 'advisor'.  Default list is presale
  const isAdvisorUser = (advisorUser && !presaleUser) || (priority === 'advisor' && advisorUser && presaleUser);

  // If user is only an advisor, set the price to 0;
  if (isAdvisorUser) {
    displayPrice = '0';
  }

  const promptForPublicMint = async () => {
    //const mintPrice = await batons.SALE_PRICE();
    const price = parseEther(mintPrice);

    return await batons.mintPublic(charityGroup, quantity, {
      value: price.mul(quantity),
    });
  }

  const promptForPresaleMint = async () => {
    const price = parseEther(mintPrice);
    const merkleProof = merkleProofFor(whitelist, presaleUser);

    return await batons.mintEarlybird(
      presaleUser.address,
      presaleUser.charity,
      presaleUser.maxMints,
      merkleProof,
      quantity,
      {
        value: price.mul(quantity),
      }
    )
  }

  const promptForAdvisorMint = async () => {
    const merkleProof = merkleProofFor(advisorlist, advisorUser);

    return await batons.mintAdvisor(
      advisorUser.address,
      advisorUser.charity,
      advisorUser.maxMints,
      merkleProof,
      quantity
    )
  }

  const mintPrompt = async () => {
    let result;
    try {
      setError(null);
      setIsSubmitting(true);
      if (isAdvisorUser) {
        console.log("advisor");
        result = await promptForAdvisorMint();
      }
      else if (presaleUser) {
        console.log("presale");
        result = await promptForPresaleMint();
      }
      else {
        console.log("public");
        result = await promptForPublicMint();
      }
      if (result.hash && onMinting instanceof Function) {
        onMinting(result.hash);
      }

      if (props.onSuccess) {
        await result.wait();
        setIsSubmitting(false);
        props.onSuccess(result);
      }
      else {
        setIsSubmitting(false);
      }
    }
    catch (error) {
      if (error.error) {
        const message = error.error.message;
        if (message.indexOf("Sale state isn't LIVE") > -1) {
          setError("Sale hasn't started yet. Check back at 10am eastern on Friday December 17.<br/>Until then, you can check if your address has been greenlisted in the early <a href='/presale'>presale</a>");
        } else if (message.indexOf("Presale hasn't started") > -1) {
          setError("Presale hasn't started yet. Check back at 10am eastern on Thursday December 16. After the presale, you can still mint from the <a href='/mint'>public sale</a>. If you were on the presale, you can still mint your presale batons here after the public sale has started.");
        } else if (message.indexOf("insufficient funds") > -1) {
          setError("Insufficient funds");
        } else {
          setError("An error has occured.  Please try again later.");
        }
      }
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    if (quantity > 0 && typeof charityGroup === 'number') {
      setCanMint(true);
    }
    else {
      setCanMint(false);
    }
  }, [charityGroup, quantity])

  return (
    <>
      {
        canMint ?
          <button className="ptb__button" onClick={mintPrompt} disabled={isSubmitting}>
            Mint {quantity} baton{quantity === 1 ? '' : 's'} for {displayPrice} Eth
          </button>
          :
          <div className="ptb__inverted">
            Select Options
          </div>
      }
      {
        error && <div className="mint__error" dangerouslySetInnerHTML={{ __html: error }} />
      }
    </>
  );
};
export default MintButton;
