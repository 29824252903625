import { useEffect, useState } from 'react';
import { mobileWidth } from 'css/variables.module.scss';

export const useScrollY = () => {
  const [scrollY, setScrollY] = useState();
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    // Send initial event
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);

  }, []);

  return scrollY;
}

export const useIsMobile = () => {
  const query = window.matchMedia(`(max-width: ${mobileWidth})`);
  const [isMobile, setIsMobile] = useState(query.matches);

  useEffect(() => {
    const handleChange = (x) => {
      setIsMobile(x.matches);
    }
    
    query.addEventListener('change', handleChange)
    return () => query.removeEventListener('change', handleChange);
  }, [query]);

  return isMobile;
}