import { useEffect, useState } from 'react';
import { utils } from 'ethers';
import { formatUnits } from '@ethersproject/units'
import { evolutionLevels } from 'components/Constants';
import 'css/gallery/coloredbar.scss';

const barPercentages = [ 20, 33, 41 ]

const ColoredBar = ({donatedAmount, evolution, team, milestones}) => {
  const [ percentage, setPercentage ] = useState(0);
  const [ level, setLevel ] = useState(0);
  const [ labels, setLabels ] = useState([]);

  useEffect(() => {
    if (!Array.isArray(milestones) || !milestones.length) { return }

    const donatedNum = utils.parseEther(donatedAmount || '0');
    let level = evolutionLevels.findIndex(name => evolution === name);
    if (level < 0) {
      level = 0;
    }
    setLevel(level);

    const labels = [];
    if (level <= 2) {
      const donationsLeft = milestones[level].sub(donatedNum);
      const donationsString = donationsLeft.gt(0) ? parseFloat(formatUnits(donationsLeft)).toFixed(1) : '0';

      labels[level] = `${donationsString} ETH to ${evolutionLevels[level + 1]}`;

      // Calculate progress bar percentage
      let percentage = 0;

      for (let i = 0; i <= level; i++) {
        if (i < level) {
          percentage += barPercentages[i];
        }
        else {
          const donatedFloat = parseFloat(formatUnits(donatedNum));
          const prevLevel =  i > 0 ? parseFloat(formatUnits(milestones[i - 1])) : 0;
          const nextLevel =  parseFloat(formatUnits(milestones[i]));

          let ratio = (donatedFloat - prevLevel) / (nextLevel - prevLevel);
          if (ratio > 1) {
            ratio = 1;
          }
          percentage += barPercentages[i] * ratio;
        }
      }

      setPercentage(percentage);
    }
    else {
      if (level === 3) {
        labels[2] = 'Ready to be morphed into a black baton';
        setPercentage(barPercentages.reduce((prev, cur) => prev + cur));
      }
      else if (level === 4) {
        labels[2] = 'Fully evolved';
        setPercentage(100);
      }
    }
    setLabels(labels);
  }, [milestones, donatedAmount, evolution])

  return <div className="coloredbar">
    <div className="coloredbar__labels-mobile">
        <span>{labels[0] || labels[1] || labels[2]}</span>
        <span>{team}</span>
    </div>
    <div className="coloredbar__bar">
      <div className={`coloredbar__progress level${level}`} style={{width: `${percentage}%`}}></div>
    </div>
    <div className="coloredbar__labels">
      <div className="coloredbar__label">
        <span>{labels[0]}</span>
        <span className="coloredbar__mobile">{labels[0] || labels[1] || labels[2]}</span>
        <div className="coloredbar__line"></div>
      </div>
      <div className="coloredbar__label">
        <span>{labels[1]}</span>
        <div className="coloredbar__line"></div>
      </div>
      <div className="coloredbar__label">
        <span>{labels[2]}</span>
        <div className="coloredbar__line"></div>
      </div>
      <div className="coloredbar__label"></div>
    </div>
  </div>
}
export default ColoredBar;