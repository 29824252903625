import { useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { teamNames } from 'shared/teams.json';
import batonEmptyImage from 'assets/images/baton_empty.png';
import MintBlackConfirmationModal from 'components/MintBlackConfirmationModal';
import PendingTransactionModal from 'components/PendingTransactionModal';
import { getIpfsGatewayUrl } from 'shared/utils';
import 'css/mint.scss';

const MintBlack = (props) => {
  const [txnId, setTxnId] = useState();
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [pendingTransactionModalIsOpen, setPendingTransactionModalIsOpen] = useState(false);
  const [batonData, setBatonData] = useState({});

  const {
    batons,
    batonsData,
    options,
    selected,
    onChange,
    onClose
  } = props;

  const handleChangeSelect = (option, teamId) => {
    const { value } = option;
    const baton = batonsData[teamId].find((baton) => baton.token_id === value);
    if (baton && onChange instanceof Function) {
      onChange(teamId, baton);
    }
  }

  const handleClickMint = async () => {
    if (!batons && selected && selected.length === 4) { return }

    const tokenIds = selected.map((baton) => baton.token_id);
    const txn = await batons.burnToMint(tokenIds);

    if (txn && txn.hash) {
      setTxnId(txn.hash);
      setPendingTransactionModalIsOpen(true);

      await txn.wait();

      setIsConfirmation(true);

      const receipt = await batons.provider.getTransactionReceipt(txn.hash);
      const iface = batons.interface;

      // Get last transaction log
      let log = receipt.logs.reverse().find((log) => iface.parseLog(log).name === 'Transfer');
      log = iface.parseLog(log);
      const { tokenId } = log.args;
      const path = await batons.tokenURI(tokenId);
      const response = await fetch(getIpfsGatewayUrl(path));
      const batonData = await response.json();
      batonData.token_id = tokenId.toNumber();

      setBatonData(batonData);
    }
  }

  const handleOnClose = () => {
    if (onClose instanceof Function) {
      onClose();
    }
    setIsConfirmation(false);
  }

  const renderMint = () => {
    return (
      <div className="mint-black">
        <div className="mint-black__header">
          <h1 className="mint-black__title">Forge a Special Black Baton</h1>
          <div className="mint-black__description">
            Combine four gold batons, one from each Legacy Team, to mint an exclusive black-on-black Legacy baton.
          </div>
        </div>
        <div className="mint-black__teams">
          {
            batonsData.map((team, i) =>
              <div className="mint-black__team" key={`team-gold-${i}`}>
                <div className="mint-black__gold-baton">
                  <div className="mint-black__gold-baton-inner">
                    <div className={`mint-black__gold-baton-image ${selected[i] ? '' : 'empty'}`}>
                      <img src={selected[i] ? selected[i].image_url : batonEmptyImage} alt="" />
                    </div>
                    <div className="mint-black__team-name">
                      {teamNames[i]}
                    </div>
                    <Select
                      className="ptb__select-container"
                      classNamePrefix="ptb__select"
                      defaultValue={options[i][0]}
                      options={options[i]}
                      onChange={(e) => handleChangeSelect(e, i)}
                    />
                  </div>
                </div>
              </div>
            )
          }
        </div>
        <div className="mint-black__border"></div>
        <div className="mint-black__subtitle">
          Black Legacy Baton
        </div>
        <div className="mint-black__button-container">
          <div className="mint-black__button-container-inner">
            <button
              className="ptb__button"
              onClick={() => setConfirmationModalIsOpen(true)}
            >
              Forge a Black Baton
            </button>
          </div>
        </div>
        {
          txnId
            ?
            <PendingTransactionModal
              txnId={txnId}
              isOpen={pendingTransactionModalIsOpen}
              onClose={() => setPendingTransactionModalIsOpen(false)}
            />
            : null
        }
        <MintBlackConfirmationModal
          isOpen={confirmationModalIsOpen}
          onClose={() => setConfirmationModalIsOpen(false)}
          onSubmit={handleClickMint}
        />
      </div>
    )
  }

  const renderConfirmation = () => {
    return (
      <div className="mint-black confirmation">
        <div className="mint-black__baton-image">
          <img src={getIpfsGatewayUrl(batonData.image)} alt="" />
        </div>
        <div className="mint-black__title">Black Legacy Baton</div>
        <div className="mint-black__subtitle">Baton #{batonData.token_id}</div>
        <div className="mint-black__button-detail">
          <Link className="ptb__button" to={`/baton/${batonData.token_id}`}>See your baton in detail</Link>
        </div>
        <div className="mint-black__button-back">
          <button className="ptb__button" onClick={handleOnClose}>View your batons</button>
        </div>
      </div>
    )
  }

  return (
    isConfirmation
      ? renderConfirmation()
      : renderMint()
  )

}

export default MintBlack;