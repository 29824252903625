import { useEffect, useState, useRef, useContext } from 'react';
import ScrollHelperContext from 'shared/ScrollHelperContext';

/**
 * Component to fade in element as it appears on the screen while scrolling.
 * Opacity is controlled by scroll distance
 */

const ScrollFade = props => {
  const el = useRef();
  const [ opacity, setOpacity ] = useState(0);
  const scrollHelper = useContext(ScrollHelperContext);

  useEffect(() => {
    if (!el.current || !scrollHelper) return;

    scrollHelper.add(el.current, {
      startY: data => data.top - (data.windowHeight * 0.8),
      endY: data => data.top - (data.windowHeight * 0.5),
      handler: value => setOpacity(value)
    });
  }, [el, scrollHelper])

  return <div className={props.className} ref={el} style={{opacity: opacity}}>
   { props.children }
  </div>
}

export default ScrollFade;