import { useEffect, useState } from 'react';
import { getContractAddress } from 'components/Constants';
import { useEthers } from '@usedapp/core';
import { useBatons } from 'hooks/useBatons';
import { normalizeBaton, fetchOpensea } from 'shared/utils';

import BatonItem from 'components/gallery/BatonItem';

const UserBatons = ({milestones}) => {
  const batons = useBatons();
  const [ batonList, setBatonList ] = useState([]);
  const { chainId, account } = useEthers();

  useEffect(() => {
    if (!batons) { return }

    const parameters = {
      asset_contract_address: getContractAddress(),
      offset: 0,
      limit: 50,
      order_direction: 'asc'
    };

    fetchOpensea('/assets', {
      chainId,
      params: {
        owner: account,
        ...parameters
      }
    }).then(resp => {
      const batons = resp.assets.map(item => normalizeBaton(item));
      setBatonList(batons);
      console.log({batons});
    });
  }, [batons, account, chainId]);

  return batonList.length ? <div>
    <div className="gallery__section-title">
      Your Batons
    </div>
    {batonList.map(baton =>
      <BatonItem baton={baton} milestones={milestones} key={baton.id} />
    )}
  </div> : null;
}

export default UserBatons;