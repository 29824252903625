import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { getAlchemyUrl, getChainId } from 'components/Constants';

export const injectedConnector = new InjectedConnector({supportedChainIds: [1,4]});

export const walletConnectConnector = new WalletConnectConnector({
  rpc: {1: getAlchemyUrl(1), 4: getAlchemyUrl(4)},
  chainId: getChainId(),
  qrcode: true,
  pollingInterval: 12000,
});

export const walletLinkConnector = new WalletLinkConnector({
  url: getAlchemyUrl(),
  appName: "Pass the Baton Wallet",
  supportedChainIds: [1, 4]
});