import { useEffect, useRef } from 'react';
import anime from 'animejs/lib/anime.es.js';
import 'css/splash/hero-mobile.scss';

import tommiePhoto from 'assets/images/splash/hero/tommie_salute_mobile.jpg';
import blackBaton from 'assets/images/splash/hero/01_BlackOnBlack.png';
import purpleBaton from 'assets/images/splash/hero/03_PurpleAndBlack.png';
import silverBaton from 'assets/images/splash/hero/04_SilverAndPurple.png';
import orangeBaton from 'assets/images/splash/hero/05_OrangeAndBlack.png';
import orangeBlueBaton from 'assets/images/splash/hero/06_OrangeBlueAndBlack.png';
import goldBaton from 'assets/images/splash/hero/08_GoldAndBlack.png';

const HeroAnimation = ({positionRatio}) => {
  const el = useRef();
  const animations = useRef([]);


  useEffect(() => {
    animations.current.push(anime({
      targets: el.current.querySelectorAll('.splash-hero__tommie'),
      easing: 'linear',
      // translateY: ['10vh', '-100vh'],
      translateY: ['15vh', '15vh'],
      opacity: [1, -1],
      elasticity: 0,
      autoplay: false
    }));

    animations.current.push(anime({
      targets: el.current.querySelectorAll('.splash-hero__black'),
      easing: 'linear',
      translateY: ['-20vh', '-200vh'],
      rotate: ['-45deg', '0deg'],
      elasticity: 0,
      autoplay: false
    }));

    animations.current.push(anime({
      targets: el.current.querySelectorAll('.splash-hero__orange'),
      easing: 'linear',
      translateY: ['-20vh', '-270vh'],
      rotate: ['-25deg', '-180deg'],
      elasticity: 0,
      autoplay: false
    }));

    animations.current.push(anime({
      targets: el.current.querySelectorAll('.splash-hero__gold'),
      easing: 'linear',
      translateY: ['-20vh', '-300vh'],
      rotate: ['-45deg', '15deg'],
      elasticity: 0,
      autoplay: false
    }));

    animations.current.push(anime({
      targets: el.current.querySelectorAll('.splash-hero__purple'),
      easing: 'linear',
      translateY: ['-20vh', '-330vh'],
      rotate: ['70deg', '-180deg'],
      elasticity: 0,
      autoplay: false
    }));

    animations.current.push(anime({
      targets: el.current.querySelectorAll('.splash-hero__silver-blur'),
      easing: 'linear',
      translateY: ['-20vh', '-380vh'],
      rotate: ['-35deg', '-60deg'],
      elasticity: 0,
      autoplay: false
    }));

    animations.current.push(anime({
      targets: el.current.querySelectorAll('.splash-hero__orange-blur'),
      easing: 'linear',
      translateY: ['-20vh', '-430vh'],
      rotate: ['-45deg', '250deg'],
      elasticity: 0,
      autoplay: false
    }));

  }, [el])

  useEffect(() => {
    if (typeof positionRatio === 'number') {
      animations.current.forEach(animation =>
        animation.seek(animation.duration * positionRatio)
      );
    }
  }, [positionRatio])

  return <div className="splash-hero" ref={el}>
    <img className="splash-hero__tommie" src={tommiePhoto} alt="Tommie Smith with fist raised" />

    <img className="splash-hero__baton splash-hero__silver-blur" src={silverBaton} alt="Silver blured baton" />
    <img className="splash-hero__baton splash-hero__orange-blur" src={orangeBlueBaton} alt="Orange blured baton" />

    <img className="splash-hero__baton splash-hero__black" src={blackBaton} alt="Black baton" />
    <img className="splash-hero__baton splash-hero__orange" src={orangeBaton} alt="Orange baton" />
    <img className="splash-hero__baton splash-hero__gold" src={goldBaton} alt="Gold baton" />
    <img className="splash-hero__baton splash-hero__purple" src={purpleBaton} alt="Purple baton" />

  </div>
}

export default HeroAnimation;