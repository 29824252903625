import { useEffect, useState, useRef, useContext } from 'react';
import ScrollHelperContext from 'shared/ScrollHelperContext';
import ScrollFade from 'components/ScrollFade';
import 'css/splash/about.scss';
import { useIsMobile } from 'shared/hooks.js';

import { Link } from 'react-router-dom';

import gkProfile from 'assets/images/splash/glenn_profile.jpg';
import tsProfile from 'assets/images/splash/tommie_profile.jpg';
import CanvasAnimation from 'components/CanvasAnimation';

const About = (props) => {
  const [positionRatio, setPositionRatio] = useState();
  const el = useRef();
  const scrollHelper = useContext(ScrollHelperContext);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!el.current || !scrollHelper) return;

    scrollHelper.add(el.current, {
      startY: (data) => data.top - data.windowHeight,
      endY: (data) => data.top,
      handler: (value) => {
        setPositionRatio(value);
      },
    });
  }, [el, scrollHelper]);

  return (
    <div className="splash-about" ref={el}>
      <div className="splash-about__row">
        <div className="splash-about__col">
          {!isMobile && (
            <CanvasAnimation
              className="splash-about__canvas"
              frameCount={95}
              positionRatio={positionRatio}
              frameImageUrl={(idx) =>
                process.env.PUBLIC_URL +
                `/sequence/baton_spin_mobile/green_baton_04_${idx.toString().padStart(5, '0')}.jpg`
              }
              width={1080}
              height={1080}
            />
          )}
        </div>
        <div className="splash-about__col">
          <ScrollFade className="splash-about__profile">
            <div className="splash-about__profile-image">
              <img alt="Tommie Smith profile" src={tsProfile} />
            </div>
            <p className="splash-about__profile-summary">
              Tommie Smith is an educator, activist, and the only man in the history of track and field to
              hold eleven world records simultaneously.
            </p>
          </ScrollFade>
          <ScrollFade className="splash-about__profile">
            <div className="splash-about__profile-image">
              <img alt="Glenn Kaino profile" src={gkProfile} />
            </div>
            <p className="splash-about__profile-summary">
              Glenn Kaino is a conceptual artist, Emmy-Award winning creator, and digital pioneer whose work
              spans an extraordinary range of media and creative activity.
            </p>
          </ScrollFade>
        </div>
      </div>
      <div className="splash-about__learn-more">
        <ScrollFade>
          <Link to="/glenn-tommie">
            <h3>Hear Glenn and Tommie's story here</h3>
          </Link>
        </ScrollFade>
      </div>
    </div>
  );
};

export default About;
