import { useEffect, useState, useRef, useContext } from 'react';
import ScrollHelperContext from 'shared/ScrollHelperContext';
import ScrollFade from 'components/ScrollFade';
import { useIsMobile } from 'shared/hooks';
import 'css/splash/how.scss';

import leftBaton from 'assets/images/splash/batons/BLACK_BLUE_BLACK.jpg';
import rightBaton from 'assets/images/splash/batons/BLUE_PINK_GOLD.jpg';

const How = (props) => {
  const el = useRef();
  const [batonX, setBatonX] = useState();
  const scrollHelper = useContext(ScrollHelperContext);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!el.current || !scrollHelper) return;

    scrollHelper.add(el.current, {
      startY: (data) => data.top - data.windowHeight,
      endY: (data) => data.bottom - data.windowHeight * 0.9,
      startRange: 400,
      endRange: 0,
      handler: (value) => setBatonX(value),
    });
  }, [el, scrollHelper]);

  const BatonImage = ({ position }) => {
    const isRight = position === 'right';

    return (
      <div className="splash-how__baton-divider">
        <img
          className={`splash-how__baton--${isRight ? 'right' : 'left'}`}
          src={isRight ? rightBaton : leftBaton}
          style={{ transform: `translate3d(${batonX * (isRight ? 1 : -1)}px,0,0)` }}
          alt="Baton divider"
        />
      </div>
    );
  };

  return (
    <div className="splash-how" ref={el}>
      <div className="splash-how__col">
        <ScrollFade className="splash-how__title">How It Works</ScrollFade>
        <ScrollFade className="splash-how__step">
          <div className="splash-how__num">1.</div>
          <div className="splash-how__text">
            <h2 className="splash-how__bullet-title">Teams</h2>
            <p>
              Each baton features a team of names that will join Tommie to form a Legacy Team—including
              activists, advocates, and changemakers like Megan Rapinoe, Minyon Moore, Jesse Williams, Derrick
              Johnson, and Brittany Packnett Cunningham.
            </p>
          </div>
        </ScrollFade>
        <ScrollFade className="splash-how__step">
          <div className="splash-how__num">2.</div>
          <div className="splash-how__text">
            <h2 className="splash-how__bullet-title">Donations</h2>
            <p>
              As batons are passed, smart contracts will trigger donations to nonprofits selected by the
              Legacy Team members on the baton.
            </p>
          </div>
        </ScrollFade>
        {!isMobile && <BatonImage position="left" />}
      </div>
      <div className="splash-how__col">
        {!isMobile && <BatonImage position="right" />}
        <ScrollFade className="splash-how__step">
          <div className="splash-how__num">3.</div>
          <div className="splash-how__text">
            <h2 className="splash-how__bullet-title">Evolutions</h2>
            <p>As donations grow, the batons evolve with rarer attributes.</p>
          </div>
        </ScrollFade>
        <ScrollFade className="splash-how__step">
          <div className="splash-how__num">4.</div>
          <div className="splash-how__text">
            <h2 className="splash-how__bullet-title">Perks</h2>
            <p>
              Active community members and baton holders will be rewarded with exclusive access, engaging
              experiences, and once-in-a-lifetime opportunities that can only be secured by our team of
              amazing partners.
            </p>
          </div>
        </ScrollFade>
        {isMobile && (
          <div className="splash-how__bottom-batons">
            <BatonImage position="left" />
            <BatonImage position="right" />
          </div>
        )}
      </div>
    </div>
  );
};

export default How;
