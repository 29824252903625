import { useEffect, useState } from 'react';
import { useEthers } from '@usedapp/core';
import { useBatons } from 'hooks/useBatons';
import { useUpdateBaton } from 'hooks/useUpdateBaton';
import { useMilestones } from 'hooks/useMilestones';
import { getContractAddress, evolutionLevels } from 'components/Constants';
import { normalizeBaton, fetchOpensea } from 'shared/utils';
import { getOpenSeaUrl } from 'components/Constants';
import { formatUnits } from '@ethersproject/units';
import ConnectModal from 'components/ConnectModal';
import DonateModal from 'components/DonateModal';
import ProgressBar from 'components/ProgressBar';
import DrawingButton from 'components/DrawingButton';
import { teamNames } from 'shared/teams.json';
import { Link } from 'react-router-dom';
import MintBlack from 'pages/MintBlack';
import 'css/common.scss';
import 'css/account.scss';

const User = () => {
  const { active, account, chainId } = useEthers();
  const batons = useBatons();
  const milestones = useMilestones();
  const [queryParams, setQueryParams] = useState({
    offset: 0,
    limit: 50,
    order_direction: 'asc'
  });
  const [batonList, setBatonList] = useState([]);
  const [batonCount, setBatonCount] = useState(0);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [connectModalIsOpen, setConnectModalIsOpen] = useState(false);
  const [donateModalIsOpen, setDonateModalIsOpen] = useState(false);
  const [isBatonsLoaded, setIsBatonsLoaded] = useState(false);
  const [collectionUrl, setCollectionUrl] = useState();
  const [selectedBaton, setSelectedBaton] = useState();
  const [isBlackBatonMintable, setIsBlackBatonMintable] = useState(false);
  const [isMintBlackBaton, setIsMintBlackBaton] = useState(false);

  const [goldBatons, setGoldBatons] = useState([]);
  const [goldBatonOptions, setGoldBatonOptions] = useState([]);
  const [selectedGoldBatons, setSelectedGoldBatons] = useState([])

  const checkAvailableGoldBatons = () => {
    if (!batonList) { return }

    const goldBatons = Array.from(Array(4), () => []);
    const options = Array.from(Array(4), () => []);
    const selected = [];

    Object.keys(batonList).forEach((page) => {
      const filteredBatons = batonList[page].filter((item) => item.evolution_level === 3);
      filteredBatons.forEach((baton) => {
        const team = teamNames.indexOf(baton.team);
        goldBatons[team].push(baton);
        options[team].push({
          value: baton.token_id,
          label: 'Baton #' + baton.token_id
        });
      });
    });

    const sum = goldBatons.map((batons) => batons.length > 0 ? 1 : 0)
      .reduce((sum, a) => sum + a, 0);

    goldBatons.forEach((batons) => {
      selected.push(batons[0]);
    });

    setGoldBatons(goldBatons);
    setGoldBatonOptions(options);
    setSelectedGoldBatons(selected);
    setIsBlackBatonMintable(sum === 4);
  }

  useEffect(() => {
    checkAvailableGoldBatons();
  }, [batonList]);

  useEffect(() => {
    if (!batons || !chainId || !account) { return }
    const processAssets = async (assets) => {
      const assetsList = assets.map(normalizeBaton);

      setShowLoadMore(assetsList.length === queryParams.limit);
      setBatonList(prevState => ({
        ...prevState,
        [queryParams.offset]: assetsList
      }));
      setIsBatonsLoaded(true);
    }

    fetchOpensea('/assets', {
      chainId,
      params: {
        owner: account,
        asset_contract_address: getContractAddress(chainId),
        ...queryParams
      }
    }).then(({assets}) => processAssets(assets));
  }, [batons, chainId, account, queryParams]);

  useEffect(() => {
    if (!batonList) { return }
    let count = 0;
    Object.keys(batonList).forEach((key) => {
      count += batonList[key].length;
    });
    setBatonCount(count);
  }, [batonList]);

  useEffect(() => {
    let url = getOpenSeaUrl(chainId);
    if (chainId === 1) {
      url += '/collection/pass-the-baton';
    } else if (chainId === 4) {
      url += '/collection/pass-the-baton-v4';
    }
    if (url) {
      setCollectionUrl(url);
    }
  }, [chainId]);

  useEffect(() => {
    if (isMintBlackBaton) {
      window.scrollTo(0,0);
    }
  }, [isMintBlackBaton]);

  const handleChangeBatonSelect = (teamId, baton) => {
    const batons = [...selectedGoldBatons]
    batons[teamId] = baton;
    setSelectedGoldBatons(batons);
  }

  const handleClickDonateButton = (baton) => {
    setSelectedBaton(baton);
    setDonateModalIsOpen(true);
  }

  const handleClickLoadMore = () => {
    const offset = queryParams.offset + queryParams.limit;
    setQueryParams(prevState => ({
      ...prevState,
      offset: offset
    }));
  }

  const handleOnDonation = async (e) => {
    if (!selectedBaton) { return }
    const newBatonList = { ...batonList };

    for (let key of Object.keys(newBatonList)) {
      const index = newBatonList[key].findIndex(item => item.token_id === selectedBaton.token_id);
      if (index >= 0) {
        const baton = newBatonList[key][index];
        // Generate a new baton object so that useUpdateBaton hook can update the data
        newBatonList[key][index] = {...baton};
        break;
      }
    }
    setBatonList(prevState => ({
      ...prevState,
      ...newBatonList
    }));
  }

  const handleOnMintBlack = () => {
    setBatonList({});
    setQueryParams(prevState => ({
      ...prevState,
      offset: 0
    }));
    setIsMintBlackBaton(false);
  }

  const renderMintBlackBaton = () => {
    return (
      <MintBlack
        batons={batons}
        batonsData={goldBatons}
        options={goldBatonOptions}
        selected={selectedGoldBatons}
        onChange={handleChangeBatonSelect}
        onClose={handleOnMintBlack}
      />
    )
  }

  const renderHasBatons = () => {
    return (
      <div className="account">
        <div className="account__header">
          <h1 className="account__title">Your Batons</h1>
          <DrawingButton />
        </div>
        <div className="account__container">
          {
            Object.keys(batonList).map((key, _) =>
              batonList[key].map(baton =>
                <BatonSummary
                  baton={baton}
                  milestones={milestones}
                  key={baton.token_id}
                  onClickDonate={() => handleClickDonateButton(baton)}
                  onEvolutionChange={checkAvailableGoldBatons}
                />
              )
            )
          }
        </div>
        {
          showLoadMore
            ? <div className="account__load-more">
              <button className="ptb__button" onClick={handleClickLoadMore}>Load More</button>
            </div>
            : null
        }
        <div className="account__footer">
          {
            !showLoadMore
              ?
              <>
                <div className="account__subtitle">Forge a Special Black Baton</div>
                <button
                  className='ptb__button'
                  disabled={!isBlackBatonMintable}
                  onClick={() => setIsMintBlackBaton(true)}
                >
                  {isBlackBatonMintable ? 'Forge a Black Baton' : 'Requires one gold baton from each legacy team'}
                </button>
                <a className="link" href="/evolutions">Learn how it works</a>
              </>
              : null
          }
          <div className="account__subtitle">Looking for Other Batons?</div>
          <a className="ptb__button" href="/gallery">Go to the Baton Gallery</a>
        </div>
      </div>
    )
  }

  const renderNoBatons = () => {
    return (
      <div className="account no-batons">
        <div className="account__content">
          <h1 className="account__title">You currently don't hold any batons</h1>
          <div className="account__butons">
            <a className="ptb__button" href={collectionUrl} rel="noreferrer" target="_blank">Find one on OpenSea</a>
            <Link className="ptb__button" to="/gallery">Find one in the gallery</Link>
          </div>
        </div>
      </div>
    )
  }

  const renderNotConnected = () => {
    return (
      <div className="account not-connected">
        <h1 className="account__title">Connect your Ethereum Wallet to view this page</h1>
        <button className="ptb__button" onClick={() => setConnectModalIsOpen(true)}>Connect Your Wallet</button>
        <div className="account__footer-text">
          Don’t worry if you’re new here. We won’t be able to make transactions until you confirm it a second time, after connecting your wallet.
        </div>
        <ConnectModal
          isOpen={connectModalIsOpen}
          onClose={() => setConnectModalIsOpen(false)}
        />
      </div>
    )
  }
  
  return (
    <>
      {
        !active || !account
          ? renderNotConnected()
          : isBatonsLoaded
            ? batonList && batonCount > 0
              ? isMintBlackBaton
                ? renderMintBlackBaton()
                : renderHasBatons()
              : renderNoBatons()
            : null
      }
      <DonateModal
        baton={selectedBaton}
        batons={batons}
        isOpen={donateModalIsOpen}
        onClose={() => setDonateModalIsOpen(false)}
        onDonate={handleOnDonation}
      />
    </>
  )
}

const BatonSummary = ({ baton, onClickDonate, milestones, onEvolutionChange }) => {
  const skipUpdate = false;
  const { batonDonations, batonEvolution, batonImage } = useUpdateBaton(baton, skipUpdate);
  const [ progress, setProgress ] = useState(0);

  useEffect(() => {
    if (!milestones.length) { return };
    const level = evolutionLevels.indexOf(batonEvolution);
    let ratio = level >= 3 ? 1 : 0;
    if (level <= 2) {
      const upgradeAmount = parseFloat(formatUnits(milestones[level]));
      ratio = parseFloat(batonDonations)/upgradeAmount;
    }
    setProgress(ratio);
  }, [milestones, batonDonations, batonEvolution]);

  useEffect(() => {
    onEvolutionChange(batonEvolution);
  }, [batonEvolution])

  return <div className="account__baton">
    <div className="account__baton-inner">
      <Link to={`/baton/${baton.id}`} className="account__baton-summary">
        <div className="account__baton-name">
          Baton #{baton.id}
        </div>
        <div className="account__baton-description">
          {baton.team}
        </div>
        <div className="account__baton-description">
          {batonEvolution}
        </div>
        <div className="account__baton-description">
          {batonDonations} ETH Donated
        </div>
      </Link>
      <Link to={`/baton/${baton.id}`} className="account__baton-image">
        <img src={batonImage} alt={`Baton #${baton.id}`} />
      </Link>
      <Link to={`/baton/${baton.id}`} className="account__baton-progress">
        <ProgressBar
          className="account__baton-progress-bar"
          progressClassName={`level-${batonEvolution}`}
          height={16}
          progress={progress}
        />
      </Link>
      <div className="account__baton-donate">
        <button
          className="ptb__button account__baton-button"
          onClick={onClickDonate}>
          Donate with Baton #{baton.id}
        </button> &nbsp;
        {/*
        <Link className="ptb__button" to={`/avatar/${baton.id}`}>Build Profile</Link>
        */}
      </div>
    </div>
  </div>
}

export default User;