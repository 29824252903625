import { useEffect, useState, useRef, useContext } from 'react';
import ScrollHelperContext from 'shared/ScrollHelperContext';
import ScrollFade from 'components/ScrollFade';
import { useIsMobile } from 'shared/hooks';
import 'css/splash/resources.scss';

import { Link } from 'react-router-dom';

import galleryLeft from 'assets/images/splash/wda_bridge_overal.jpeg';
import galleryRight from 'assets/images/splash/wda_bridge_closeup.jpeg';
import lacmaLogo from 'assets/images/logos/lacma_logo.png';
import smithLogo from 'assets/images/logos/smithsonian_logo.png';
import bgBaton from 'assets/images/splash/batons/PURPLE_YELLOW_BLACK.png';

const Resources = () => {
  const el = useRef();
  const [ batonY, setBatonY] = useState();
  const [ photoY, setPhotoY] = useState();
  const [ footerOpacity, setFooterOpacity] = useState();
  const scrollHelper = useContext(ScrollHelperContext);
  const isMobile = useIsMobile()

  useEffect(() => {
    if (!el.current || !scrollHelper) return;

    scrollHelper.add(el.current, {
      startY: data => data.top - (data.windowHeight),
      endY: data => data.bottom,
      startRange: 200,
      endRange: -200,
      handler: value => setBatonY(value)
    });

    scrollHelper.add(el.current, {
      startY: data => data.top - (data.windowHeight * 0.5),
      endY: data => data.bottom - (data.windowHeight * 0.4),
      startRange: 200,
      endRange: 0,
      handler: (value) => setPhotoY(value)
    });

    scrollHelper.add(el.current, {
      startY: data => data.top - (data.windowHeight * 0.3),
      endY: data => data.bottom - (data.windowHeight * 0.8),
      handler: (value) => setFooterOpacity(value)
    });
  }, [el, scrollHelper])

  const GalleryImages = () => <>
    <div className="splash-np__gallery">
      <img className="splash-np__gallery-top" alt="Wide gallery" src={galleryLeft} />
      <img className="splash-np__gallery-bottom" alt="Close-up gallery" src={galleryRight} style={{transform: `translate3d(0,${photoY}px,0)`}}/>
    </div>
  </>

  return <div className="splash-np" ref={el}>
    <div className="splash-np__bg" style={{transform: `translate3d(0,${batonY}vh,0)`}}>
      <img src={bgBaton} alt="background baton"/>
    </div>
    <ScrollFade className="splash-np__title">
      Collaborators<br/>and Partners
    </ScrollFade>
    <div className="splash-np__content">
      { !isMobile && <GalleryImages/> }
      <ScrollFade className="splash-np__description">
        <p>
          Pass the Baton proudly supports 24 non-profit organizations that continue the fight for equality. Civil rights is an intergenerational struggle, and this project creates vital ongoing support for those that carry the baton for future generations.
        </p>
        <p>
          Proceeds from the sale will also enable the artist to support the acquisition of the monumental sculpture Invisible Man (Salute) by the Los Angeles County Museum of Art and the large-scale installation Bridge by the Smithsonian American Art Museum. 
        </p>
        <Link to='/partners'>
          <h3>See the full Legacy Team here</h3>
        </Link>
        { isMobile && <GalleryImages/> }
        {/*
        <div className="splash-np__learn">
          <div className="social-button__inverted">
            Learn More
          </div>
        </div>
        */}
        <div className="splash-np__footer" style={{opacity: footerOpacity}}>
          <img className="splash-np__logo" src={lacmaLogo} alt="LACMA Logo" />
          <img className="splash-np__logo" src={smithLogo} alt="Smithsonian Logo" />
        </div>
      </ScrollFade>
    </div>
  </div>
}

export default Resources;