import { useEffect, useState } from 'react';
import { useEthers } from '@usedapp/core';
import { utils } from 'ethers';
import { Link } from 'react-router-dom';
import { useContract } from 'hooks/useContract';
import { contractType, getDrawingEventId } from 'components/Constants';
import allowMap from 'shared/first500.json';
import 'css/common.scss';

const DrawingButton = () => {
  const { account, chainId } = useEthers()
  const [ claimableAmount, setClaimableAmount ] = useState(0);
  const contract = useContract(contractType.drawing);

  useEffect(() => {
    let allowedAmount = 0;

    if (!contract || !account || !chainId) return;
    const addrKey = Object.keys(allowMap).find(key => 
      utils.getAddress(account) === utils.getAddress(key)
    );
    if (addrKey && Number.isInteger(allowMap[addrKey])) {
      allowedAmount = allowMap[account]
    }
    else {
      return
    }

    (async () => {
      const drawingEventId = getDrawingEventId(chainId);
      try {
        const claimableUntil = (await contract.claimableUntil(drawingEventId)).toNumber();
        const claimed = (await contract.claimed(drawingEventId, account)).toNumber();
        const claimable = allowedAmount - claimed;
        if (Date.now() < (claimableUntil * 1000)) {
          setClaimableAmount(claimable);
        }
      }
      catch (error) {
        console.log({error})
      }
    })();
  }, [contract, account, chainId]);

  if (claimableAmount > 0) {
    return <div className="account__claim-btn">
      <Link className="ptb__button" to="/drawing">Claim Relay NFT</Link>
    </div>
  }
  return null
}

export default DrawingButton;