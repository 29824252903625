import { useEffect, useRef, useContext } from 'react';
import ScrollHelperContext from 'shared/ScrollHelperContext';

const CanvasAnimation = props => {
  const canvasEl = useRef();
  const canvasContext = useRef();
  const imgEl = useRef(new Image());
  const prevFrame = useRef();
  const didAutoPlay = useRef(false);
  //const [ display, setDisplay ] = useState('hidden');
  const scrollHelper = useContext(ScrollHelperContext);

  const {
    frameCount,
    positionRatio,
    width,
    height,
    autoPlayTo,
    scrollOptions
  } = props;

  const willAutoPlay = () => typeof positionRatio === 'number' && autoPlayTo && positionRatio <= 0;

  const currentFrame = index => {
    let idx = index;
    if (index < 0) {
      idx = 0;
    }
    else if (index > (frameCount - 1)) {
      idx = frameCount - 1;
    }

    return props.frameImageUrl(idx);
  }

  const preloadImages = () => {
    const promises = [];
    for (let i = 0; i < frameCount; i++) {
      const img = new Image();
      let deferred;
      img.src = currentFrame(i);
      promises.push(new Promise((resolve, reject) => deferred = resolve));
      img.onload = () => deferred()
    }

    return Promise.all(promises);
  };

  const loadFirstFrame = () => {
    const img = imgEl.current;
    img.src = currentFrame(0);
    img.onload=function(){
      canvasContext.current.drawImage(img, 0, 0);
    }
  }

  const playToFrame = () => {
    //setDisplay('block');
    let count = 0;
    didAutoPlay.current = true;
    const interval = setInterval(() => {
      // Hack to stop playback
      if (count >= autoPlayTo || window.scrollY > 0) {
        clearInterval(interval);
      }
      updateImage(count, true);
      count++;
    }, 70);
  }

  const updateImage = (index) => {
    const img = imgEl.current;
    img.src = currentFrame(index);
    if (prevFrame.current !== index) {
      prevFrame.current = index;
      window.requestAnimationFrame(() => {
        canvasContext.current.drawImage(img, 0, 0);
      })
    }
  }

  useEffect(() =>{
    if (!canvasEl.current || !scrollHelper) return;

    canvasContext.current = canvasEl.current.getContext('2d');
    preloadImages().then(() => {
      if (willAutoPlay()) {
        playToFrame();
      }
      else {
        loadFirstFrame();
      }
    });

    if (scrollOptions) {
      scrollHelper.add(scrollOptions);
    }
  }, [canvasEl, scrollHelper])

  useEffect(() => {
    if (typeof positionRatio !== 'number') {
      return;
    }

    if (autoPlayTo) {
      // If already played, load frame
      if (didAutoPlay.current) {
        const startFrame = autoPlayTo;
        const endFrame = frameCount - 1;
        const delta = endFrame - startFrame;
        const frameIndex = startFrame + Math.round(positionRatio * delta);

        updateImage(frameIndex);
      }
      // Otherwise play through intro if ratio == 0
      else if (positionRatio === 0) {
        playToFrame();
      }
    }
    else {
      const frameIndex = Math.round(positionRatio * (frameCount - 1));
      updateImage(frameIndex);
    }

    /*
    const changeDisplay = str => {
      if (display !== str) {
        setDisplay(str);
      }
    }

    if (showBeforeStart && positionRatio === 0) {
      changeDisplay('visible');
    }
    else if (showAfterEnd && positionRatio === 1) {
      changeDisplay('visible');
    }
    else if (positionRatio > 0 && positionRatio < 1) {
      changeDisplay('visible');
    }
    else {
      changeDisplay('hidden');
    }
    */
  }, [positionRatio, canvasEl]);

  return <canvas
    className={props.className}
    id={props.id}
    ref={canvasEl}
    width={width}
    height={height}
  />

}

export default CanvasAnimation;