import { useEffect, useState } from 'react';
import { partnerInfo } from 'shared/partner-info';
import 'css/partners.scss';

function PartnersPopup({ name, organization, partnerImage, orgImage, onClose }) {
  const [personDescription, setPersonDescription] = useState(partnerInfo[name?.toLowerCase() ?? '']);
  const [orgDescription, setOrgDescription] = useState(partnerInfo[organization?.toLowerCase() ?? '']);

  useEffect(() => {
    setPersonDescription(partnerInfo[name?.toLowerCase() ?? '']);
    setOrgDescription(partnerInfo[organization?.toLowerCase() ?? '']);
  }, [name, organization]);

  return (
    <>
      <div className="partners-popup">
        <div className="partners-content">
          <div className="popup-close-wrapper">
            <div className="partners-popup__close">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="none"
                viewBox="0 0 32 32"
                onClick={onClose}
              >
                <path
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M27.2 4.8L4.8 27.2M4.8 4.8l22.4 22.4"
                ></path>
              </svg>
            </div>
          </div>
          <div className="partners-content__split">
            <div className="pc__group">
              {
                partnerImage
                  ? <div className="pc__group__img" style={{ backgroundImage: `url(${partnerImage})` }} />
                  : <div className="pc__group__img" style={{ backgroundImage: `url(https://pass-the-baton-partners.vercel.app/${name?.toLowerCase().replaceAll('é', 'e').replaceAll(',', '').replaceAll(' ', '-')}.png)` }} />
              }
              <h2 className="pc__group__title">{name}</h2>
              <p className="pc__group__text" dangerouslySetInnerHTML={{ __html: personDescription }} />
            </div>
            <div className="pc__group">
              {
                orgImage
                  ? <div className="pc__group__img" style={{ backgroundImage: `url(${orgImage})` }} />
                  : <div className="pc__group__img" style={{ backgroundImage: `url(https://pass-the-baton-partners.vercel.app/${organization?.toLowerCase().replaceAll('.', '').replaceAll(',', '').replaceAll(' ', '-').replaceAll("'", "")}.png)` }} />
              }
              <h2 className="pc__group__title">{organization}</h2>
              <p className="pc__group__text" dangerouslySetInnerHTML={{ __html: orgDescription }} />
            </div>
          </div>
        </div>
        <div className="partners-popup__underlay" onClick={onClose} />
      </div>
    </>
  );
}

export default PartnersPopup;