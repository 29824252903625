import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Layout from './pages/Layout';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function App() {
  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  );
}

export default App;
