import { useEthers } from '@usedapp/core';
import { useEffect, useState } from 'react';
import { teamNames, teams, organizations } from 'shared/teams.json';

const TeamSelector = props => {
  const { account } = useEthers();
  const [selectedQuantity, setSelectedQuantity] = useState(4);
  const [selectedCharity, setSelectedCharity] = useState();
  const [selectedRemaining, setSelectedRemaining] = useState();
  const { batons, onChange, charityOptions, presaleUser, advisorUser } = props;
  const [maxMints, setMaxMints] = useState(4);
  const [remaining, setRemaining] = useState([]);
  const [mintable, setMintable] = useState([]);

  const handleSelect = index => {
    setSelectedCharity(index);
    if (presaleUser || advisorUser) {
      if (mintable[index] !== undefined && mintable[index] >= 0) {
        setSelectedRemaining(mintable[index]);
        setSelectedQuantity(mintable[index]);
      }
    } else if (remaining[index] !== undefined && remaining[index] >= 0) {
      setSelectedRemaining(remaining[index]);
    }
  }

  const handleQuantity = (amt) => {
    let quantity;
    if (typeof amt === 'number') {
      quantity = (selectedQuantity || 0) + amt;
    }
    else if (typeof amt === 'string') {
      if (/^\d+$/.test(amt)) {
        quantity = parseInt(amt);
      }
      else {
        setSelectedQuantity('');
        return;
      }
    }

    if (quantity < 0) {
      setSelectedQuantity(0);
    }
    else if (typeof selectedRemaining === 'number' && quantity > selectedRemaining) {
      setSelectedQuantity(selectedRemaining);
    }
    else if (!presaleUser && !advisorUser && quantity > maxMints) {
      setSelectedQuantity(maxMints);
    }
    else {
      setSelectedQuantity(quantity);
    }
  }

  useEffect(() => {
    if (!onChange) { return }
    onChange({
      charity: selectedCharity,
      quantity: selectedQuantity || 0,
    });
  }, [selectedCharity, selectedQuantity, onChange]);

  useEffect(() => {
    // Hack: check remaining.length so that we don't have to set it again
    if (!batons || !account || remaining.length) return;

    (async () => {
      const isPresale = presaleUser || advisorUser;
      const remainingMints = await Promise.all(teams.map(async (team, charityId) => {
        const maxMints = charityOptions[charityId];
        let mintable;
        if (presaleUser) {
          const numMinted = await batons.getEarlyBirdMints(account, charityId);
          mintable = maxMints - numMinted.toNumber();
        } else if (advisorUser) {
          const numMinted = await batons.getAdvisorMints(account, charityId);
          mintable = maxMints - numMinted.toNumber();
        }
        const numMintable = await batons.numMintableBatons(charityId);
        return { remaining: numMintable.toNumber(), mintable: mintable };
      }));
      setRemaining(remainingMints.map((remaining) => remaining.remaining));

      if (isPresale) {
        setMintable(remainingMints.map((remaining) => Math.max(remaining.mintable, 0)));
      }

      // Pre-select the first option available
      if (selectedCharity === undefined) {
        const index = remainingMints.findIndex(num => (isPresale ? num.mintable : num.remaining) > 0);
        if (index >= 0) {
          const maxMints = isPresale ? charityOptions[index] : 4;
          const remaining = isPresale
            ? remainingMints[index].mintable
            : remainingMints[index].remaining;
          setSelectedCharity(index);
          setSelectedRemaining(remaining);
          setSelectedQuantity(Math.min(remaining, maxMints));
        }
        else {
          setSelectedQuantity(0);
          setMaxMints(0);
        }
      }
    })();
  }, [account, batons, charityOptions, presaleUser, advisorUser, remaining, selectedCharity])

  return <>
    <div className="mint__section-title">
      1. Join one of Tommie Smith's legacy teams
    </div>
    {presaleUser ? <div className="mint__section-description">
      During pre-sale, the Legacy Teams have been randomly selected as we wanted to promote an even sellout across all four teams.
    </div> : null}
    <div className="mint__team-selector">
      {teams.map((team, i) =>
        <TeamOption
          key={i}
          batons={batons}
          team={team}
          charityId={i}
          teamName={teamNames[i]}
          isSelected={i === selectedCharity}
          maxMints={charityOptions && charityOptions[i]}
          mintable={mintable && mintable[i]}
          remaining={remaining && remaining[i]}
          presaleUser={presaleUser}
          advisorUser={advisorUser}
          onClick={() => handleSelect(i)}
        />
      )}
    </div>
    <div className="mint__section-title">
      2. Choose how many batons
    </div>
    <div className="mint__section-description">
      {
        (presaleUser || advisorUser) && selectedRemaining !== undefined
          ? <>You've been greenlisted for up to {selectedRemaining} batons for this team.  </>
          : <>You can mint up to {maxMints} batons per transaction. Multiple transactions permitted. </>
      }
    </div>

    <div className="mint__quantity-input">
      <div className="mint__quantity-less" onClick={() => handleQuantity(-1)}>
        -
      </div>
      <div className="mint__quantity-current">
        <input className="mint__quantity-field" type="text" value={selectedQuantity} onChange={(e) => handleQuantity(e.target.value)}/>
      </div>
      <div className="mint__quantity-more" onClick={() => handleQuantity(1)}>
        +
      </div>
    </div>
  </>
}

const Organization = ({ name }) => {
  const org = organizations.find(org => org[0] === name);
  if (org) {
    return <span className="mint__member-organization">{org[1]}</span>
  }
  else {
    return null;
  }
}

const TeamOption = ({ team, teamName, onClick, isSelected, maxMints, remaining, mintable }) => {
  const isDisabled = maxMints === 0 || remaining === 0;
  const handleSelect = () => {
    if (!isDisabled) {
      onClick();
    }
  }

  return <div className={`mint__team-option ${isDisabled ? 'disabled' : ''}`} onClick={() => handleSelect()}>
    <div className="mint__team-inner">
      <div className="mint__team-name">
        {teamName}
      </div>
      <div className="mint__remaining-count">
        {typeof mintable === 'number' ? <span className="mintable">{mintable} mintable</span> : <span>&nbsp;</span>}
        {typeof remaining === 'number' ? 
          <div className="mint__progress-bar" style={{ position: 'relative', width: '100%', height: 16, marginTop: -8, borderRadius: '48px', border: '1px solid rgba(255, 255, 255, 0.25)', overflow: 'hidden' }}>
            <div style={{ position: 'absolute', width: `${remaining / 1968 * 100}%`, height: 14, background: '#ffffff', borderRadius: '48px' }} />
            {remaining}
          </div> 
        : <span>&nbsp;</span>}
      </div>
      {team.map((name) =>
        <div key={name} className="mint__member-name">
          {name} <Organization name={name} />
        </div>
      )}
      <div className={`mint__option-${isSelected ? 'radio--selected' : 'radio'}`}></div>
    </div>
  </div>
}

export default TeamSelector;