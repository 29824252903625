import { useEffect, useRef, useState, useContext } from 'react';
import ScrollHelperContext from 'shared/ScrollHelperContext';
import { Link } from 'react-router-dom';
import { useIsMobile } from 'shared/hooks';
import 'css/splash/header.scss';

const Header = props => {
  // const [menuY, setMenuY] = useState(-100);
  const [menuY, setMenuY] = useState(0);
  const [menuOpacity, setMenuOpacity] = useState(0);

  const el = useRef();
  const scrollHelper = useContext(ScrollHelperContext);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!el.current) return;

    if (scrollHelper) {
      scrollHelper.add(el.current, {
        startY: isMobile ? window.innerHeight : 0,
        endY: isMobile ? window.innerHeight + 100 : 100,
        startRange: -100,
        endRange: 0,
        handler: (value, ratio) => {
          // setMenuY(value);
          setMenuOpacity(ratio);
        },
      });
    } else {
      setMenuY(0);
      setMenuOpacity(1);
    }
  }, [el, scrollHelper, isMobile]);

  return (
    <div
      className={`splash-header ${props.className || ''}`}
      ref={el}
      style={{
        transform: `translate3d(0, ${menuY}%, 0)`,
        opacity: menuOpacity,
      }}
    >
      <div className="splash-header__title splash-desktop">
        <Link to="/">Pass The Baton</Link>
      </div>
      <div className="splash-header__button">
        <a
          className="social-button__discord splash-header__discord"
          href="https://discord.gg/passthebaton"
          target="_blank"
          rel="noreferrer"
        >
          Join Our Discord
        </a>
      </div>
      <div className="splash-header__button splash-desktop">
        <a className="social-button__twitter" href="https://twitter.com/passthebaton" target="_blank" rel="noreferrer">
          Follow Us On Twitter
        </a>
      </div>
    </div>
  );
};

export default Header;
