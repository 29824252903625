import { useEffect, useState } from 'react';
import { evolutionLevels } from 'components/Constants';
import { getIpfsGatewayUrl, normalizeBaton } from 'shared/utils';
import { formatUnits } from '@ethersproject/units';
import { usePublicBatons } from 'hooks/useBatons';
import { teamNames } from 'shared/teams.json';
import placeholderImage from 'assets/images/placeholder.png';

export const useUpdateBaton = (baton, skipUpdates) => {

  const contract = usePublicBatons();
  const [ batonImage, setImage ] = useState(baton.image || placeholderImage);
  const [ batonEvolution, setEvolution ] = useState(baton.evolution || 'common');
  const [ batonDonations, setDonations ] = useState(baton.donated_amount || '0');
  const [ batonTeam, setTeam ] = useState(baton.team);

  useEffect(() => {
    if (skipUpdates || !contract || !baton) { return };
    if (!baton.is_normalized) {
      throw 'Baton data not normalized';
    }

    const tokenId = baton.id;

    const getIpfsData = async () => {
      try {
        const path = await contract.tokenURI(tokenId);
        const response = await fetch(getIpfsGatewayUrl(path));
        return response.json();
      }
      catch(error) {
        console.log({error});
        return null
      }
    }

    getIpfsData().then(ipfsData => {
      if (ipfsData) {
        const batonData = normalizeBaton(ipfsData, tokenId);
        baton.image = getIpfsGatewayUrl(batonData.image)
        setImage(baton.image)
      }
    });

    contract.getBatonDonation(tokenId).then(value => {
      baton.donated_amount = formatUnits(value);
      setDonations(baton.donated_amount)
    });

    let evolutionName = baton.evolution;
    if (evolutionName === 'black') {
      baton.evolution_level = evolutionLevels.indexOf(evolutionName);
    } else {
      contract.getEvolutionLevel(tokenId).then(evolution => {
        const evolutionLevel = evolution.toNumber();
        baton.evolution = evolutionLevels[evolutionLevel];
        baton.evolution_level = evolutionLevel;
        setEvolution(baton.evolution);
      });
    }

    if (!baton.team) {
      contract.charityGroupForToken(tokenId).then(id => {
        baton.team = teamNames[id.toNumber()];
        setTeam(baton.team);
      });;
    }
  }, [contract, baton, skipUpdates])

  return { batonImage, batonEvolution, batonDonations, batonTeam };
}
