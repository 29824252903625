import { useEffect, useState } from 'react';
import { usePublicBatons } from 'hooks/useBatons';
import { formatUnits } from '@ethersproject/units';
export const useMilestones = () => {
  const [ milestones, setMilestones ] = useState([]);
  const batons = usePublicBatons();
  useEffect(() => {
    if (!batons) { return }

    Promise.all([
      batons.BRONZE_UPGRADE(),
      batons.SILVER_UPGRADE(),
      batons.GOLD_UPGRADE()
    ]).then(setMilestones);
  }, [batons]);

  return milestones;
}