import { useState, useEffect } from 'react';
import { useEthers } from '@usedapp/core';

export function useSigner() {
  const [signer, setSigner] = useState(null);

  const { active, account, library } = useEthers();

  useEffect(() => {
    if (!active || !library || !account) {
      // All dependencies need to be valid
      return;
    }
    setSigner(library.getSigner());
  }, [library, account, active]);

  return signer;
}