class AvatarRenderer {

  createCanvas(width, height) {
    const canvasEl = window.document.createElement('canvas');
    canvasEl.style.display = 'none';
    canvasEl.style.visibility = 'hidden';
    canvasEl.width = width || this.width;
    canvasEl.height = height || this.height;
    window.document.body.appendChild(canvasEl);

    return canvasEl;
  }

  loadImage(src) {
    return new Promise(resolve => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => resolve(img);
      img.src = src;
    });
  }

  removeCanvas(el) {
    el.parentNode.removeChild(el);
  }

  /**
   * Shared clientside/serverside methods
   */

  constructor(width, height) {
    this.width = width || 2048;
    this.height = height || 2048;
    this.cache = {};
    this.imageData = {};
  }

  degreesToRadians(degrees) {
    return degrees * (Math.PI/180);
  }

  rotateBaton(image) {
    const canvasEl = this.createCanvas();
    const ctx = canvasEl.getContext('2d');
    ctx.save();  
    // Translate to the center point of our image  
    ctx.translate(this.width * 0.5, this.height * 0.5);  
    // Perform the rotation  
    ctx.rotate(this.degreesToRadians(45));  
    // Translate back to the top left of our image  
    ctx.translate(-this.width * 0.5, -this.height * 0.5);  
    // Finally we draw the image  
    ctx.drawImage(image, 0, 0, 1700, 1700, this.width * 0.125, this.height * 0.125, this.width * 0.75, this.height * 0.75)
    // And restore the context ready for the next loop  
    ctx.restore();  
    this.removeCanvas(canvasEl);
    return canvasEl;
  }

  async setImageData(options) {
    const {
      grayscaleImage,
      colormapImage,
      batonImage,
      circleFullImage,
      circleHalfImage
    } = options;

    const imgData = await Promise.all([
      this.loadImageData(grayscaleImage),
      this.loadImageData(colormapImage),
      this.loadImageData(batonImage, true),
      this.loadImageData(circleFullImage, true),
      this.loadImageData(circleHalfImage, true),
    ]);

    this.imageData = {
      grayscaleImage: imgData[0],
      colormapImage: imgData[1],
      batonImage: imgData[2],
      circleFullImage: imgData[3],
      circleHalfImage: imgData[4]
    };

    return this.imageData;
  }

  async loadImageData(src, rawImage) {
    const cacheKey = `${src}\t${Boolean(rawImage)}`
    if (this.cache[cacheKey]) {
      return this.cache[cacheKey];
    }

    const img = await this.loadImage(src);
    this.cache[cacheKey] = img;

    if (rawImage) {
      return img;
    }

    const canvasEl = this.createCanvas();
    const ctx = canvasEl.getContext("2d");

    ctx.drawImage(img, 0, 0);
    const imgData = ctx.getImageData(0, 0, canvasEl.width, canvasEl.height).data;
    const pixelData = [];
    let count = 0;

    for (let i = 0; i < imgData.length; i += 4) {
      const pixel = {
        r: imgData[i+0],
        g: imgData[i+1],
        b: imgData[i+2],
        a: imgData[i+3]
      }
      count++;
      pixelData.push(pixel);
    }
    this.removeCanvas(canvasEl);
    this.cache[cacheKey] = pixelData;
    return pixelData;
  }


  drawAvatar({grayImgData, colorImgData, colors}) {
    const canvasEl = this.createCanvas();
    const ctx = canvasEl.getContext("2d");

    let pixelIndex = 0;
    const outputData = ctx.createImageData(this.width, this.height);
    for (let i = 0; i < outputData.data.length; i += 4) {
      // Modify pixel data
      const colorPixel = colorImgData[pixelIndex]
      const color = {r:0, g:0, b:0};

      // Calculate color #1 via red channel
      const ratio0 = colorPixel.r / 255;
      color.r += colors[0].r * ratio0;
      color.g += colors[0].g * ratio0;
      color.b += colors[0].b * ratio0;

      // Calculate color #2 via green channel
      const ratio1 = colorPixel.g / 255;
      color.r += colors[1].r * ratio1;
      color.g += colors[1].g * ratio1;
      color.b += colors[1].b * ratio1;

      // Calculate color #3 via blue channel
      const ratio2 = colorPixel.b / 255;
      color.r += colors[2].r * ratio2;
      color.g += colors[2].g * ratio2;
      color.b += colors[2].b * ratio2;

      outputData.data[i + 0] = Math.min(255, Math.round(color.r * (grayImgData[pixelIndex].r/255)));
      outputData.data[i + 1] = Math.min(255, Math.round(color.g * (grayImgData[pixelIndex].g/255)));
      outputData.data[i + 2] = Math.min(255, Math.round(color.b * (grayImgData[pixelIndex].b/255)));
      outputData.data[i + 3] = grayImgData[pixelIndex].a;  // A value
      pixelIndex++;
    }
    ctx.putImageData(outputData, 0, 0);
    return canvasEl;
  }

  renderImage(canvasEl, colors) {
    const canvas = (canvasEl || this.createCanvas());
    const ctx = canvas.getContext("2d");
    const { imageData, width, height } = this;
    const avatarCanvas = this.drawAvatar({
      grayImgData: imageData.grayscaleImage,
      colorImgData: imageData.colormapImage,
      colors
    });

    ctx.drawImage(imageData.circleFullImage, 0, 0, width, height)
    const batonCanvas = this.rotateBaton(imageData.batonImage);
    ctx.drawImage(batonCanvas,
      width * 0.4, // sx
      0,           // sy
      width * 0.2, // sw
      height,      // sh
      width * 0.2, // dx
      0,           // dy
      width * 0.2, // dw
      height       // dh
    )
    ctx.drawImage(avatarCanvas,
      0,           // sx
      0,           // sy
      width,       // sw
      height,      // sh
      width * 0.17,// dx
      height * 0.1,// dy
      width * 0.75,// dw
      height * 0.75// dh
    )
    ctx.drawImage(imageData.circleHalfImage, 0, 0, width, height)
    return  canvas;
  }

}

export default AvatarRenderer;