import { useState } from 'react';
import 'css/common.scss';
import 'css/gallery/filter.scss';

const EvolutionFilter = ({onChange, className}) => {
  const evolutionStates = ['Common', 'Bronze', 'Silver', 'Gold', 'Black'];
  const [ filters, setFilters ] = useState({});

  const handleClickFilter = name => {
    const key = name.toLowerCase();
    const updatedFilters = {
      ...filters,
      [key]: !filters[key]
    };
    setFilters(updatedFilters);
    const sortedKeys = Object.keys(updatedFilters).filter(key => updatedFilters[key]).sort();
    onChange && onChange(sortedKeys);
  };

  return <div className={className}>
    <ul className="gallery__evolution-states">
      {evolutionStates.map(name =>
        <li
          className={`gallery__evolution-option ${filters[name.toLowerCase()] ? 'selected' : ''}`}
          key={name}
          onClick={() => handleClickFilter(name)}
        >
          <span className="checkbox" />
          {name}
        </li>
      )}
    </ul>
  </div>
}

export default EvolutionFilter;