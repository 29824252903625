import 'css/progressbar.scss';

const ProgressBar = props => {
  const {progress, height, label, className, progressClassName, title, subtitle} = props;
  const barHeight = height || 25;

  return <div className={`progress ${className || ''}`}>
    {(title || subtitle)  &&
    <div className="progress__header">
      {title && <div className="progress__title">{title}</div>}
      {subtitle && <div className="progress__subtitle">{subtitle}</div>}
    </div>}
    <div className="progress__background">
      <div className={`progress__current ${progressClassName}`} style={{
        width: (progress * 100).toFixed(2) + '%',
        height: barHeight + 'px',
        lineHeight: barHeight + 'px',
        fontSize: (barHeight < 20 ? 14 : 16) + 'px'
      }}>
      </div>
      <div className="progress__text">{Math.round(progress * 100)}%{label ? ` • ${label}` : ''}</div>
    </div>
  </div>
}

export default ProgressBar;