export const OpenSeaApiKey = 'e2434d30d05746619ade2165afcdb61f';

const GalleryTestApiUrl = 'https://kgj7d228ri.execute-api.us-east-1.amazonaws.com';
const GalleryApiUrl = 'https://fs33gfn57k.execute-api.us-east-1.amazonaws.com';
const AlchemyMainnetUrl = 'https://eth-mainnet.alchemyapi.io/v2/TfR0NmNZguVsyfggH_8WvbdxvZ92PVAC';
const AlchemyRinkebyUrl = 'https://eth-rinkeby.alchemyapi.io/v2/QPHb-D-9if00grQn1XSCgZnQYEF0eurE';
const EtherscanMainnetUrl = 'https://etherscan.io';
const EthercanRinkebyUrl = 'https://rinkeby.etherscan.io';
const OpenSeaApiUrl = 'https://api.opensea.io/api/v1';
const OpenSeaRinkebyApiUrl = 'https://rinkeby-api.opensea.io/api/v1';
const OpenSeaUrl = 'https://opensea.io';
const OpenSeaRinkebyUrl = 'https://testnets.opensea.io';
const drawingEventId = 0;
const drawingTestEventId = 3;

export const contractType = {
  baton: 0,
  drawing: 1,
  profile: 2,
}

const contract = {
  [contractType.baton]: {
    main: '0x6cdf9A2aC2068e43bb3571aD9EE544EF26300e6A',
    test: '0x3fd60a4bdC41764f49c8783546A4F41915CEF550'
  },
  [contractType.drawing]: {
    main: '0xED2AAbDbF3F24Ac71BCFA62DD9943C0bc7b33C5b',
    test: '0x4DE35379Ad6224Aef26Ca9f90e62E4d031539fce'
  },
  [contractType.profile]: {
    main: '0x4DE35379Ad6224Aef26Ca9f90e62E4d031539fce',
    test: '0x074C9b762b795422872Eb5B46fE92F60f9472905'
  },
}

export const AvatarApiUrl = `https://us-central1-pass-the-baton-af9f3.cloudfunctions.net/api`;

// For calculating `TotalRaised.js`
export const ArtistAndDevSplitterAddress = "0x7c6d9fB9cccBdF25366ce69ea04013BED37532d2";
export const TommieWalletAddress = "0x5Bcf552625358DBF29b913efF08E17BDA3706051";
export const TommieSharesOfSplitter = 3571 
export const TotalSharesOfSplitter = 9998;

export const evolutionLevels = [ 'common', 'bronze', 'silver', 'gold', 'black' ];
const isTest = id => id === 4 || window.localStorage.defaultWallet === '1';

export const getAlchemyUrl = id => isTest(id) ? AlchemyRinkebyUrl : AlchemyMainnetUrl;
export const getEtherscanUrl = id => isTest(id) ? EthercanRinkebyUrl : EtherscanMainnetUrl;
export const getOpenSeaApiUrl = id => isTest(id) ? OpenSeaRinkebyApiUrl : OpenSeaApiUrl;
export const getOpenSeaUrl = id => isTest(id) ? OpenSeaRinkebyUrl : OpenSeaUrl;
export const getContractAddress = (id, type) => contract[type || contractType.baton][isTest(id) ? 'test' : 'main'];
export const getGalleryApiUrl = id => isTest(id) ? GalleryTestApiUrl : GalleryApiUrl;
export const getChainId = () => isTest() ? 4 : 1;
export const getDrawingEventId = (id) => isTest(id) ? drawingTestEventId : drawingEventId;