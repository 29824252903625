import { useState } from 'react';
import Modal from 'react-modal';

const DonateModal = (props) => {
  const { isOpen, onClose, onSubmit } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setIsSubmitting(false);
    if (onClose instanceof Function) {
      onClose()
    }
  }

  const handleOnSubmit = () => {
    setIsSubmitting(true);
    if (onSubmit instanceof Function) {
      onSubmit();
      handleClose();
    }
  }

  return (
    <Modal
      className="modal mint-black-confirmation"
      overlayClassName="modal__overlay"
      isOpen={isOpen}
      onRequestClose={handleClose}
    >
      <div className="modal__close" onClick={handleClose} />
      <div className="modal__title">Are you sure?</div>
      <div className="modal__description">
        This transaction is irreversible and will destroy 4 Gold batons, but it'll get you an awesome black baton.
      </div>
      <button 
        className="ptb__button" 
        disabled={isSubmitting}
        onClick={handleOnSubmit}
        >
          Forge Black Baton
        </button>
    </Modal>
  )
}

export default DonateModal;