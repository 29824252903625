import { useEffect, useState } from 'react';
import { ethers, utils } from 'ethers';
import { usePublicBatons } from 'hooks/useBatons';
import { formatUnits } from '@ethersproject/units';
import { ArtistAndDevSplitterAddress, TommieSharesOfSplitter, TotalSharesOfSplitter, TommieWalletAddress } from 'components/Constants';
import 'css/common.scss';
import 'css/gallery/total.scss';

const TotalRaised = ({className}) => {
  const batons = usePublicBatons();
  const [ totalEth, setTotalEth ] = useState();
  const [ totalUsd, setTotalUsd ] = useState();
  
  useEffect(() => {
    if (!batons) { return }

    const getTotal = async () => {
      try {
        const nonProfits = await batons.getDonationsFromProject();
                
        const pSplitter = new ethers.Contract(
          ArtistAndDevSplitterAddress,
          [ // readable version of ABI, abstract later
            'function released(address) external view returns (uint256)',
            'function totalReleased() public view returns (uint256)'
          ],
          batons.provider
        )

        // lifetime amount received by this payment splitter is how much it has now + how much it released.
        const pSplitterBalance = await batons.provider.getBalance(ArtistAndDevSplitterAddress);
        const totalReleased = await pSplitter.totalReleased();
        const totalReceivedPSplitter = pSplitterBalance.add(totalReleased); 

        // Entitled to a fixed percentage of the lifetime amount paid to this splitter
        const amountToTommie = totalReceivedPSplitter.mul(TommieSharesOfSplitter).div(TotalSharesOfSplitter);
        
        // And the total amount for this section is comprised of Tommie's portion plus the nonprofit portion
        return nonProfits.add(amountToTommie);
      }
      catch (error) {
        console.log({error});
        return utils.parseEther('0');
      }
    };

    const getConversion = async () => {
      const resp = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
      const { ethereum } = await resp.json();
      return ethereum;
    }

    (async () => {
      const [ total, conversion ] = await Promise.all([
        getTotal(),
        getConversion()
      ]);
      const totalUsd = Math.round(parseFloat(formatUnits(total)) * conversion.usd);
      const formattedUsd = totalUsd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      setTotalUsd(formattedUsd);
      setTotalEth(parseFloat(formatUnits(total)).toFixed(2));
    })();
  }, [batons])

  return <div className={className}>
    <div className="gallery__total">
      <div className="gallery__total-title">
        Total Donated
      </div>
      <div className="gallery__total-subtitle">
        To Tommie Smith &amp; 24 non-profits
      </div>
      <div className="gallery__totals">
        <div className="gallery__total-eth">
          {totalEth} ETH
        </div>
        <div className="gallery__total-usd">
          ${totalUsd} USD
        </div>
      </div>
    </div>
  </div>
}

export default TotalRaised;