import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { useIsMobile } from 'shared/hooks';

import Header from 'components/splash/Header';
import SpinningBatons from 'components/SpinningBatons';
import 'css/fwb.scss';

const HashedFriends = () => {
  const location = useLocation();
  const [inputValue, setInputValue] = useState('');
  const [amountValue, setAmountValue] = useState(1);
  const [emailValue, setEmailValue] = useState('');
  const [response, setResponse] = useState(null);
  const [isSuccess, setIsSuccess] = useState(null);
  
  const isMobile = useIsMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const onInputChange = (e) => {
    setInputValue(e.target.value);
    setResponse(null);
  }

  const onAmountChange = (e) => {
    setAmountValue(e.target.value);
    setResponse(null);
  }

  const onEmailChange = (e) => {
    setEmailValue(e.target.value);
    setResponse(null);
  }

  const updateCount = (value) => {
    if (value == -1) {
      if (amountValue > 1) {
        setAmountValue(amountValue + value);
      }
    }

    if (value == 1) {
      if (amountValue < 10) {
        setAmountValue(amountValue + value);
      }
    }
  }

  const onFormSubmit = (e) => {
    e.preventDefault();

    // console.log('OnSubmit', inputValue, emailValue);

    // axios.get(`http://localhost:8000/fxx?address=${inputValue}&email=${emailValue}`)
    axios.get(`https://baton-collab.vercel.app/hashed-friends?address=${inputValue}&amount=${amountValue}&email=${emailValue}`)
    .then(function (response) {
      console.log(response);
      setResponse('Your address has been recorded for presale. Minting for the presale runs December 16, 7a-7p pacific here at baton.art and the public sale starts the next day. Join us on Discord to stay up to date on the latest.');
      setIsSuccess('success');
    })
    .catch(function (error) {
      console.log(error);
      setResponse('oops something went wrong');
      setIsSuccess('error');
    });

    // console.log(e, inputValue);
  }

  return (
    <>
    {isMobile ? (
          <div className="splash-header__title--mobile">
              <Link to="/">Pass The Baton</Link>
            </div>
      ):(
        <Header className="splash-desktop" />
      )
    }
      <div className="fxx" style={{ background: 'url(/fxx-background.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <div className="fxx__content">
          <SpinningBatons />
          <h2 className="fxx__content__description">Friends of Hashed</h2>
          <div className="fxx__success">
          <h2 className="fxx__content__description">Thanks for your support</h2>
          <h4 className="fxx__response">We've reached capacity for this page, but come back for our main sale December 17.<br/>For more opportunities and to get reminded, join us on Discord and Twitter:</h4>
          <a href="https://discord.gg/passthebaton" target="_blank">
          <button className="fxx__response__button">Join our Discord</button>
          </a>
          <a href="https://twitter.com/passthebaton" target="_blank">
          <button className="fxx__response__button">Follow our Twitter</button>
          </a>
        </div>
        </div>
      </div>
    </>
  );
};

export default HashedFriends;