import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useEthers } from '@usedapp/core';
import { useBatons } from 'hooks/useBatons';
import { whitelist } from 'shared/presale.json';
import { advisorlist } from 'shared/advisors.json';
import { formatUnits } from '@ethersproject/units';
import { getEtherscanUrl, getOpenSeaUrl } from 'components/Constants';
import MintButton from 'components/minting/MintButton';
import TeamSelector from 'components/minting/TeamSelector';
import ConnectModal from 'components/ConnectModal';
import batonImage from 'assets/images/baton_large.png';
import PendingTransactionModal from 'components/PendingTransactionModal';
import { shortenString } from 'shared/utils';
import batonLoop from 'assets/videos/baton_placeholder.mp4';
import 'css/common.scss';
import 'css/mint.scss';

const Mint = ({ isPresale, priority }) => {
  const { active, account, library, chainId } = useEthers();
  const batons = useBatons(library);
  const [mintOptions, setMintOptions] = useState({});
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [mintPrice, setMintPrice] = useState();
  const [connectModalIsOpen, setConnectModalIsOpen] = useState(false);
  const [pendingTransactionModalIsOpen, setPendingTransactionModalIsOpen] = useState(false);
  const [txnId, setTxnId] = useState();
  const [txnUrl, setTxnUrl] = useState();
  const [openSeaUrl, setOpenSeaUrl] = useState();
  const [presaleUser, setPresaleUser] = useState();
  const [advisorUser, setAdvisorUser] = useState();
  const [charityOptions, setCharityOptions] = useState([]);
  const [saleState, setSaleState] = useState();

  useEffect(() => {
    if (!account) { return }
    const presaleUser = whitelist.find(item => item.address === account);
    const advisorUser = advisorlist.find(item => item.address === account);
    setPresaleUser(presaleUser);
    setAdvisorUser(advisorUser);

    let charityOptions = [];

    if (isPresale && (presaleUser || advisorUser)) {
      charityOptions = [0, 0, 0, 0];
      const usersList = whitelist;
      const optionsList = usersList.filter(user => user.address === account);
      optionsList.forEach(option => charityOptions[option.charity] = option.maxMints)
      setCharityOptions(charityOptions);
    }
  }, [isPresale, priority, account]);

  useEffect(() => {
    if (!batons || !account) { return }

    batons.SALE_PRICE().then(mintPrice =>
      setMintPrice(formatUnits(mintPrice))
    );

    batons.saleState().then(state => {
      setSaleState(state);
    });
  }, [batons, account]);

  useEffect(() => {
    if (typeof mintOptions.charity !== 'undefined') {
      const presaleUser = whitelist.find(item =>
        item.address === account && item.charity === mintOptions.charity
      );
      const advisorUser = advisorlist.find(item =>
        item.address === account && item.charity === mintOptions.charity
      );
      setPresaleUser(presaleUser);
      setAdvisorUser(advisorUser);
    }
  }, [mintOptions, account]);

  const handleMintMore = () => {
    setIsConfirmation(false);
    setTxnId(null);
    setTxnUrl(null);
  }

  const handleOnMinting = (txnId) => {
    if (!txnId) { return };
    setTxnId(txnId);
    setPendingTransactionModalIsOpen(true);
  }

  const handleOnMinted = async (txn) => {
    if (!txn || !batons || !chainId) { return }

    let txnUrl = getEtherscanUrl(chainId)
    let openSeaUrl = getOpenSeaUrl(chainId)
    if (txnUrl && txn.hash) {
      txnUrl += '/tx/' + txn.hash;
      setTxnUrl(txnUrl);
    }
    if (openSeaUrl && account) {
      openSeaUrl += '/' + account;
      setOpenSeaUrl(openSeaUrl);
    }

    window.scrollTo(0, 0);
    setIsConfirmation(true);
  }

  if (!active || !account) {
    return <div className="account not-connected">
      <h1 className="account__title">Connect your Ethereum Wallet to view this page</h1>
      <button className="ptb__button" onClick={() => setConnectModalIsOpen(true)}>Connect Your Wallet</button>
      <div className="account__footer-text">
        Don’t worry if you’re new here. We won’t be able to make transactions until you confirm it a second time, after connecting your wallet.
      </div>
      <ConnectModal
        isOpen={connectModalIsOpen}
        onClose={() => setConnectModalIsOpen(false)}
      />
    </div >;
  }

  const renderNotGreenlisted = () => {
    return <div className="mint not-greenlisted">
      <div className="mint__title">
        {
          saleState !== 'undefined'
            ? saleState === 0
              ? <>Presale starts Thursday December 16 at 10am eastern.</>
              : <>The connected address is not on the greenlist. When the main sale is live, starting Friday December 17 at 10am eastern, you can mint <a href="/mint">here</a>.</>
            : null
        }
      </div>
    </div>;
  }

  const renderConfirmation = () => {
    return (
      <div className="mint confirmation" style={{ textAlign: 'center' }}>
        <div className="mint__baton-video">
          <video className="mint__baton-loop" autoPlay={true} muted loop>
            <source src={batonLoop} type="video/mp4" />
          </video>
        </div>
        {
          txnUrl && txnId
            ? <div className="mint__link"><a href={txnUrl} rel="noreferrer" target="_blank">{shortenString(txnId, 14)}</a></div>
            : null
        }
        <div className="mint__title">
          Successfully Minted.
        </div>
        <div className="mint__subtitle">
          Your baton's specific visuals will be revealed a few hours after mint completion to prevent an unfair minting process.
        </div>
        <button className="ptb__button" onClick={handleMintMore}>Mint more batons</button>
        <div className="mint__link large">
          <a href={openSeaUrl} rel="noreferrer" target="_blank">View on OpenSea</a>
        </div>
        <div className="mint__link large">
          <Link to="/gallery">Visit the Baton Gallery</Link>
        </div>
      </div>
    )
  }

  const renderMint = () => {
    return (
      <div className="mint">
        <h1 className="mint__title">Mint: {mintPrice} Eth per Baton</h1>
        <div className="mint__row">
          <div className="mint__col-left">
            <TeamSelector
              onChange={setMintOptions}
              presaleUser={isPresale && presaleUser}
              advisorUser={isPresale && advisorUser}
              batons={batons}
              charityOptions={charityOptions}
            />
            <div style={{ margin: '20px' }}>
              <MintButton
                batons={batons}
                mintPrice={mintPrice}
                charityGroup={mintOptions.charity}
                quantity={mintOptions.quantity}
                presaleUser={isPresale && presaleUser}
                advisorUser={isPresale && advisorUser}
                priority={priority}
                onMinting={handleOnMinting}
                onSuccess={handleOnMinted}
              />
            </div>
          </div>
          <div className="mint__col-right">
            <img src={batonImage} alt="Baton" />
          </div>
        </div>
        {
          txnId
            ? <PendingTransactionModal
              txnId={txnId}
              isOpen={pendingTransactionModalIsOpen}
              onClose={() => setPendingTransactionModalIsOpen(false)}
            />
            : null
        }
      </div>
    )
  }

  return (
    <>
      {
        !isPresale || presaleUser || advisorUser
          ? isConfirmation
            ? renderConfirmation()
            : renderMint()
          : renderNotGreenlisted()
      }
    </>
  )
}

export default Mint;