import { useEffect, useRef, useState, useContext } from 'react';
import ScrollHelperContext from 'shared/ScrollHelperContext';
import { useIsMobile } from 'shared/hooks';
import moment from 'moment-timezone';

import 'css/splash/intro.scss';

import unopndLogo from 'assets/images/logos/unopnd_logo.png';
import hashedLogo from 'assets/images/logos/hashed_logo.png';
import visibilityLogo from 'assets/images/logos/visibility_logo.png';
import paceLogo from 'assets/images/logos/pace_logo.png';
import CanvasAnimation from 'components/CanvasAnimation';
import HeroAnimation from 'components/splash/HeroAnimation';
import { Link } from 'react-router-dom';

const saleStartDate = moment(1639753200 * 1000); // 12/17/2021 @ 7am unixtimestamp.com
const saleStartDateText = `${saleStartDate.format('MMMM D ha')} ${moment.tz(moment.tz.guess()).zoneAbbr()}`;
const presaleStartDate = moment(1639666800 * 1000); // 12/16/2021 @ 7am unixtimestamp.com
const presaleStartDateText = `${presaleStartDate.format('MMMM D ha')} ${moment.tz(moment.tz.guess()).zoneAbbr()}`;

const Intro = () => {
  const el = useRef();
  const [positionRatio, setPositionRatio] = useState();
  const [contentOpacity, setContentOpacity] = useState(1);
  const scrollHelper = useContext(ScrollHelperContext);
  const isMobile = useIsMobile();


  useEffect(() => {
    if (!el.current || !scrollHelper) return;

    scrollHelper.add(el.current, {
      startY: (data) => data.top,
      endY: (data) => data.bottom - data.windowHeight * 0.5,
      handler: (value) => {
        setPositionRatio(value);
      },
    });

    scrollHelper.add(el.current, {
      startY: (data) => data.top,
      endY: (data) => data.bottom - data.windowHeight * 0.8,
      startRange: 1,
      endRange: 0,
      handler: (value) => {
        if (!isMobile) {
          setContentOpacity(value);
        }
      },
    });
  }, [el, scrollHelper, isMobile]);

  return (
    <div className="splash-intro" ref={el}>
      {isMobile ? (
        <HeroAnimation positionRatio={positionRatio} />
      ) : (
        <CanvasAnimation
          className="splash-intro__canvas"
          frameCount={200}
          positionRatio={positionRatio}
          frameImageUrl={(idx) =>
            process.env.PUBLIC_URL +
            `/sequence/hero_v8/hero05_${idx.toString().padStart(5, '0')}.jpg`
          }
          width={1920}
          height={1080}
        />
      )}
      <div className="splash-intro__inner" style={{ opacity: contentOpacity }}>
        <div className="splash-intro__byline">
          <span>Glenn Kaino</span> &times; <span>Tommie Smith</span>
        </div>
        <div className="splash-intro__center">
          <div className="splash-intro__content">
            {isMobile ? (
              <h1 className="splash-intro__title">
                Pass the
                <br />
                 Baton
              </h1>
            ) : (
              <h1 className="splash-intro__title">
                Pass the Baton
              </h1>
            )}
            <div className="splash-intro__button">
              <Link className="ptb__button ptb__button--large" to="/mint">Mint Your Baton Now</Link>
            </div>
          </div>
        </div>
        <div className="splash-intro__footer">
          <img className="splash-intro__logo-unopnd" alt="Onopnd Logo" src={unopndLogo} />
          <img className="splash-intro__logo-hashed" alt="Hashed Logo" src={hashedLogo} />
          <img className="splash-intro__logo-visibility" alt="Visibility Logo" src={visibilityLogo} />
          <img className="splash-intro__logo-pace" alt="Pace Logo" src={paceLogo} />
        </div>
      </div>
    </div>
  );
};

export default Intro;
