import { useState, useEffect } from 'react';
import 'css/fwb.scss';

const SpinningBatons = () => {
	const [isGoogleChrome, setIsGoogleChrome] = useState(false);
// 	const [isBadBrowser, setIsBadBrowser] = useState(false);
// 
// 	function checkIfSafari() {
// 	    const navigator = window.navigator;
// 	    const ua = navigator.userAgent.toLowerCase();
// 	    const hasMediaCapabilities = !!(navigator.mediaCapabilities && navigator.mediaCapabilities.decodingInfo);
// 	    const isSafari =
// 	      ua.indexOf('safari') != -1 && !(ua.indexOf('chrome') != -1) && ua.indexOf('version/') != -1;
// 	    return isSafari && hasMediaCapabilities;
// 	}
// 
// 	function checkIfIE() {
// 	    return !!window.navigator.userAgent.match(/Trident\/7\./, []);
// 	}
// 
// 	useEffect(() => {
// 		setIsBadBrowser(checkIfSafari || checkIfIE);
// 	}, []);

	useEffect(() => {
		var isChromium = window.chrome;
		var winNav = window.navigator;
		var vendorName = winNav.vendor;
		var isOpera = typeof window.opr !== "undefined";
		var isIEedge = winNav.userAgent.indexOf("Edg") > -1;
		var isIOSChrome = winNav.userAgent.match("CriOS");

		if (isIOSChrome) {
		// is Google Chrome on IOS
		} else if(
		isChromium !== null &&
		typeof isChromium !== "undefined" &&
		vendorName === "Google Inc." &&
		isOpera === false &&
		isIEedge === false
		) {
			setIsGoogleChrome(true);
		// is Google Chrome
		} else { 
		}
		
	}, []);

	return (
		<div className="fww__batons">
            <img className="fww__batons__main" src={'/spinning-batons-compressed.gif'} alt="" />
            {!isGoogleChrome && <img className="fww__batons__background" src={'/spinning-batons-compressed.gif'} alt="" />}
        </div>
	);	
	// return (
	// 	<div className="fww__batons">
 //            {isBadBrowser ? (
 //              <img src={'/spinning-batons-compressed.gif'} />
 //            ) : (
 //              <video
 //                id="fww__batons__player"
 //                src={'/spinning-batons.webm'}
 //                loop
 //                muted
 //                autoplay
 //                playsinline
 //              ></video>
 //            )}
 //        </div>
	// );
}

export default SpinningBatons;