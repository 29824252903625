import React, { useEffect, useState } from 'react';
import { usePublicBatons } from 'hooks/useBatons';
import { formatUnits } from '@ethersproject/units';
import 'css/common.scss';
import 'css/evolutions.scss';

import batonCommonImage from 'assets/images/evolutions/baton_common.jpg';
import batonBronzeImage from 'assets/images/evolutions/baton_bronze.jpg';
import batonSilverImage from 'assets/images/evolutions/baton_silver.jpg';
import batonGoldImage from 'assets/images/evolutions/baton_gold.jpg';
import batonBlackImage from 'assets/images/evolutions/baton_black.jpg';
import batonTeam1Image from 'assets/images/evolutions/baton_team_1.jpg';
import batonTeam2Image from 'assets/images/evolutions/baton_team_2.jpg';
import batonTeam3Image from 'assets/images/evolutions/baton_team_3.jpg';
import batonTeam4Image from 'assets/images/evolutions/baton_team_4.jpg';

const evolutionData = [
  {
    name: 'Common',
    image: batonCommonImage
  },
  {
    name: 'Bronze',
    image: batonBronzeImage
  },
  {
    name: 'Silver',
    image: batonSilverImage
  },
  {
    name: 'Gold',
    image: batonGoldImage
  },
];

const Evolutions = () => {
  const batons = usePublicBatons()
  const [upgrades, setUpgrades] = useState([]);
  const [milestones, setMilestones] = useState([]);

  useEffect(() => {
    if (!batons) { return }

    (async () => {
      const milestones = [];
      const upgrades = await Promise.all([
        batons.BRONZE_UPGRADE(),
        batons.SILVER_UPGRADE(),
        batons.GOLD_UPGRADE()
      ])

      const deltas = upgrades.map((num, idx) => 
        (idx - 1) < 0 ? num : num.sub(upgrades[idx - 1])
      );

      upgrades.forEach((num, idx) => {
        milestones[idx] = {
          value: num,
          label: evolutionData[idx + 1].name,
          image: evolutionData[idx + 1].image
        };
      });

      milestones.unshift({
        value: 0,
        label: evolutionData[0].name,
          image: evolutionData[0].image
      });

      setUpgrades(deltas);
      setMilestones(milestones);
    })();

  }, [batons]);

  return (
    <div className="evolutions">
      <div className="evolutions__states">
        <span>Common</span><span className="right-arrow-icon" />
        <span>Bronze</span><span className="right-arrow-icon" />
        <span>Silver</span><span className="right-arrow-icon" />
        <span>Gold</span><span className="right-arrow-icon" />
        <span>Black</span>
      </div>
      <div className="evolutions__title">How Batons Evolve</div>
      <div className="evolutions__description">
        Your baton evolves when one of three donation milestones is hit.
        You can do this on-chain through the individual baton page.
      </div>

      <div className="evolutions__bar">
        {upgrades.map((num, i) => <div className="evolutions__bar-text" key={i}>
          { formatUnits(num) } ETH
        </div>)}
        <div className="evolutions__bar-text">&nbsp;</div>
      </div>
      <div className="evolutions__milestones">
        {milestones.map(item => <div className="evolutions__milestone" key={item.label}>
          <div className="evolutions__milestone-line"></div>
          <div className="evolutions__milestone-description">
            <div className="evolutions__milestone-image">
              <img src={item.image} alt={`${item.label} baton`}/>
            </div>
            <div className="evolutions__milestone-title">
              {item.label}
            </div>
            <div className="evolutions__milestone-subtitle">
              {item.value ? `${formatUnits(item.value)} ETH Donated` : 'On Mint'} 
            </div>
          </div>
        </div>)}
      </div>
      <div className="evolutions__donations">
        {evolutionData.map((item, index) => <React.Fragment key={item.name}>
          <div className="baton">
            <span className="image">
              <img src={item.image} alt=""/>
            </span>
            {upgrades.length && milestones.length &&
              <MobileDetails
                upgrade={index > 0 && upgrades[index - 1]}
                milestone={milestones[index]}
                index={index}
              />
            }
          </div>
        </React.Fragment>)}
        <div className="baton empty-spacer">
          <span className="image"/>
          <div className="evolutions-mobile__details" data-index={4}>
            <div className="evolutions-mobile__milestone-description"></div>
            <div className="evolutions-mobile__bar"></div>
          </div>
        </div>
      </div>
      <div className="evolutions__subtitle">Forge A Special Black Baton</div>
      <div className="evolutions__section-description">
        When you combine four gold batons, one from each of the four Legacy Teams, you mint a final-form black-on-black baton.
      </div>
      <div className="evolutions__black">
        <div className="baton">
          <div className="image">
            <img src={batonTeam1Image} alt=""/>
          </div>
          <div className="text">
            <div className="title">Team Togetherness</div>
            <div className="subtitle">Gold Baton</div>
          </div>
        </div>
        <div className="baton">
          <div className="image">
            <img src={batonTeam2Image} alt=""/>
          </div>
          <div className="text">
            <div className="title">Team Strength</div>
            <div className="subtitle">Gold Baton</div>
          </div>
        </div>
        <div className="baton">
          <div className="image">
            <img src={batonTeam3Image} alt=""/>
          </div>
          <div className="text">
            <div className="title">Team Power</div>
            <div className="subtitle">Gold Baton</div>
          </div>
        </div>
        <div className="baton">
          <div className="image">
            <img src={batonTeam4Image} alt=""/>
          </div>
          <div className="text">
            <div className="title">Team Unity</div>
            <div className="subtitle">Gold Baton</div>
          </div>
        </div>
      </div>
      <div className="evolutions__black-border" />
      <div className="evolutions__black-border-title">Final Evolution Black Baton</div>
      <div className="evolutions__black-baton">
        <img src={batonBlackImage} alt=""/>
      </div>
    </div>
  )
}

const MobileDetails = props => {
  const {upgrade, milestone, index} = props;
  return <div className="evolutions-mobile__details" data-index={index}>
    <div className="evolutions-mobile__milestone-description">
      <div className="evolutions-mobile__milestone-description-text">
        <div className="evolutions-mobile__milestone-title">
          {milestone.label}
        </div>
        <div className="evolutions-mobile__milestone-subtitle">
          {milestone.value ? `${formatUnits(milestone.value)} ETH Donated` : 'On Mint'} 
        </div>
      </div>
    </div>
    <div className="evolutions-mobile__bar-text">
      <span>{ upgrade ? `${formatUnits(upgrade)} ETH` : '' }</span>
    </div>
    <div className="evolutions-mobile__bar"></div>
    <div className="evolutions-mobile__milestone-line"></div>
  </div>
}

export default Evolutions;