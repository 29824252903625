import { useState, useEffect } from 'react';
import { useBatons } from 'hooks/useBatons';
import { whitelist } from 'shared/presale.json';
import { advisorlist } from 'shared/advisors.json';
import { merkleRootFromAllowlist } from 'shared/helpers';
import 'css/devtools.scss';


const DevTools = () => {
  const batons = useBatons();
  const [ contractState, setContractState ] = useState();
  const [ defaultWallet, setDefaultWallet ] = useState(0);

  const changeWallet = ev => {
    const value = parseInt(ev.currentTarget.value);
    if (value) {
      window.localStorage.defaultWallet = value;
    }
    else {
      window.localStorage.removeItem('defaultWallet');
    }
    setDefaultWallet(value)
  }

  useEffect(() => {
    if (!batons) { return }

    batons.saleState().then(currentState => {
      switch (currentState) {
        case 1: setContractState("Presale"); break;
        case 2: setContractState("Public Sale"); break;
        default: setContractState("Stopped");
      }
    });

    setDefaultWallet(parseInt(window.localStorage.defaultWallet || 0))

  }, [batons])


  const setMerkleRoot = async () => {
    // set earlybird list
    try {
      const root = merkleRootFromAllowlist(whitelist);
      const result = await batons.setEarlybirdMerkleRoot(root);
      console.log({result});
    }
    catch (e) {
      console.log(e);
    }
  };

  const setAdvisorMerkleRoot = async () => {
    // set earlybird list
    try {
      const root = merkleRootFromAllowlist(advisorlist);
      const result = await batons.setAdvisorMerkleRoot(root);
      console.log({result});
    }
    catch (e) {
      console.log(e);
    }
  };

  const startPresale = async () => {
    try {
      const result = await batons.startPresale();
      console.log({result});
    }
    catch (e) {
      console.log(e);
    }
  }

  const startSale = async () => {
    try {
      const result = await batons.startSale();
      console.log({result});
    }
    catch (e) {
      console.log(e);
    }
  }

  const stopSale = async () => {
    try {
      const result = await batons.stopSale();
      console.log({result});
    }
    catch (e) {
      console.log(e);
    }
  }

  return <div className="devtools">
    {contractState && <h1 style={{font:'900 30px sans-serif', marginBottom: 30}}>Contract State: {contractState}</h1>}
    <fieldset>
      Wallet: &nbsp;
      <select onChange={changeWallet} value={defaultWallet}>
        <option value={0}>Use Mainnet</option>
        <option value={1}>Use Rinkeby</option>
      </select>
    </fieldset>
    <fieldset>
      <button onClick={setMerkleRoot}>Set EARLYBIRD Merkle Root</button>
    </fieldset>
    <fieldset>
      <button onClick={setAdvisorMerkleRoot}>Set ADVISOR Merkle Root</button>
    </fieldset>
    <fieldset>
      <button onClick={startPresale}>Start Presale</button>
    </fieldset>
    <fieldset>
      <button onClick={startSale}>Start Public Sale</button>
    </fieldset>
    <fieldset>
      <button onClick={stopSale}>Stop Sale</button>
    </fieldset>
  </div>
}

export default DevTools;