import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { organizations } from 'shared/teams.json';
import Header from 'components/splash/Header';
import PartnersPopup from 'components/PartnersPopup';
import 'css/common.scss';
import 'css/partners.scss';

const partnersList = organizations;
const Partners = () => {
  const location = useLocation();

  const [popupName, setPopupName] = useState(null);
  const [popupOrg, setPopupOrg] = useState(null);
  const [partnerImage, setPartnerImage] = useState(null);
  const [orgImage, setOrgImage] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const onPartnerClick = async partner => {
    const [name, org, image, orgImage] = await Promise.all([
      partner[0],
      partner[1],
      partner[2] && import(`assets/partners/${partner[2]}`),
      partner[3] && import(`assets/partners/${partner[3]}`)
    ]);
    // Dyamic imports require part of the path to be static.
    // https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import

    setPopupName(name);
    setPopupOrg(org);
    setPartnerImage(image ? image.default : null);
    setOrgImage(orgImage ? orgImage.default : null);
  }

  const onPopupClose = () => {
    setPopupName(null);
    setPopupOrg(null);
  }

  return (
    <>
      <Header className="splash-desktop" />
      <div className="partners">
        <h1 className="partners__title">The Legacy Team</h1>
        <p className="partners__subtitle">These are the activists, advocates, and changemakers carrying forward Tommie's stand for human rights.</p>
        <div className="partners__list">
          {partnersList.map((partner) => (
            <div className="partners__row" key={partner[0]} onClick={() => onPartnerClick(partner)}>
              <div className="partners__action">
                <div className="partners__name">{partner[0]}</div>
                <div className="partners__description">{partner[1]}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {(popupName || popupOrg) && <PartnersPopup name={popupName} organization={popupOrg} partnerImage={partnerImage} orgImage={orgImage} onClose={onPopupClose} />}
    </>
  );
};

export default Partners;