const getFaq = () => {
  return [
    {
      "question": "So what is an NFT?",
      "answer": "NFTs are digital tokens that represent ownership of unique digital assets, like art and collectibles. They have a finite supply and can only have one owner at a time, unlike other digital files which can have unlimited indistinguishable copies. Their ownership and transaction history is publicly available on a blockchain—more on that in a second—making it easy to verify ownership and confirm authenticity. So they are limited, authenticated, and exchangeable."
    },
    {
      "question": "Why should authenticity matter?",
      "answer": "Sometimes you’ll find a vintage piece of furniture and you’ll see a tag on the bottom of it that shows you when it was made and who made it. Or you’ll buy a piece of art with a certificate of authenticity attached to it. Now, you may have bought that chair or painting without concern for whether or not it was authentic—you may have just really liked the way they looked. However, if you ever wanted to resell those items, the value they have to a potential buyer may very well come from knowing whether or not they are buying an original or a reproduction. In that case, you’ll be happy to have that tag or certificate."
    },
    {
      "question": "Well then what is a blockchain?",
      "answer": "A blockchain is a transparent and publicly accessible ledger—think about balancing your checkbook, but on a massive, digital scale, where anyone can see and verify each transaction."
    },
    {
      "question": "Are blockchains secure?",
      "helper": "I’ve heard a lot about people losing all their cryptocurrency.",
      "answer": "Blockchains are incredibly secure and tamper-proof. They safely transfer digital assets using public key encryption, and maintain the security of the network through decentralized consensus. "
    },
    {
      "question": "Wait, stop. What is public key encryption?",
      "answer": "It is a system for securing information that uses pairs of keys. Each pair consists of a public key (which people can see) and a private key (which only the owner knows). Any person can encrypt data using the intended receiver's public key, but that encrypted data can only be decrypted with the receiver's private key. When you hear about people losing all of their cryptocurrency, usually this is because they have lost the private key to their digital wallet."
    },
    {
      "question": "And decentralized consensus?",
      "answer": "Computers all around the world work together to verify each transaction, making it nearly impossible to forge. You can’t hack a single node and change the data, because it needs to be verified by the other computers on the network. You would have to tamper with every computer on the network simultaneously. It is a transparent way to digitally protect the truth, and to achieve security and trust without relying on the central control of a bank, corporation, or government."
    },
    {
      "question": "Are NFTs connected to cryptocurrency?",
      "answer": "Many NFT projects, including Pass the Baton, are built on the Ethereum blockchain. Because they are digital assets that exist on a blockchain, they are often bought and sold with cryptocurrency."
    },
    {
      "question": "Do real people actually buy these things?",
      "answer": "NFTs have exploded in popularity over the past year. They have even been sold by major international auction houses like Christie’s and Sotheby’s, and are in the collections of a growing number of major museums like MoMA, LACMA, and the Smithsonian."
    },
    {
      "question": "How can I buy, sell, or trade NFTs?",
      "answer": "You’ll need a digital wallet to buy, sell, or trade NFTs. MetaMask and Coinbase Wallet are popular options. Once you have your wallet you’ll need to buy some Ether (ETH), the cryptocurrency that powers the Ethereum blockchain. Many NFT projects, including Pass the Baton, are built on the Ethereum blockchain, because of one of its unique features, called smart contracts."
    },
    {
      "question": "Ok, so what is a smart contract?",
      "answer": "Like any traditional written contract, a smart contract is a written agreement between two parties. Unlike traditional contracts however, they are written in computer code and are embedded in a blockchain where many of the stages of the transaction are self-executing—that is, they happen automatically. Artists can use smart contracts to specify royalty payments anytime their work resells, allowing them to see a piece of the action when others profit from their work. Or, in the case of the The Pass the Baton project, artists can use smart contracts to ensure sustained financial support for dozens of impactful organizations doing inspiring work."
    },
    {
      "question": "Are NFTs good for artists?",
      "answer": "The fact that NFTs create a means of authentication on a grand scale has allowed artists to challenge some long-held ideas about ownership and what makes something valuable. It has also allowed them to think differently about how they and others are compensated for their work through something called smart contracts that are coded into NFTs. Everyone from musicians to dancers to game developers are finding unique and interesting applications of the technology."
    },
    {
      "question": "Still confused? Want to learn more?",
      "answer": "The best way for your questions to get answered and to learn how the ecosystem works is to jump into our Discord. We hope to see you there!"
    }
  ]
}

export default getFaq;