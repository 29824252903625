const { ethers } = require("ethers");
const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");
/* Constructor for all hte tests */
export const constructorArgs = (opts) => {
  if (!opts) opts = {};
  return [
    //opts.merkleRoot ? opts.merkleRoot : ethers.constants.HashZero,
    opts.vrf ? opts.vrf : ethers.constants.AddressZero,
    opts.link ? opts.link : ethers.constants.AddressZero,
    opts.vrfKeyHash ? opts.vrfKeyHash : ethers.constants.HashZero,
    opts.vrfFee ? opts.vrfFee : 0,
    opts.treasurer ? opts.treasurer : ethers.constants.AddressZero,
  ];
}

/* Merkle Leaf methods */
export const merkleLeaf = (blob) =>
  ethers.utils.solidityKeccak256(
    ["address", "uint256", "uint256"],
    [blob.address, blob.charity, blob.maxMints]
  );
export const merkleRootFromAllowlist = (allowlist) => {
  const merkleLeaves = allowlist.map((elem) => merkleLeaf(elem));
  const merkleTree = new MerkleTree(merkleLeaves, keccak256, { sort: true });
  const merkleRoot = merkleTree.getHexRoot();
  return merkleRoot;
};
export const merkleProofFor = (allowlist, entry) => {
  const merkleLeaves = allowlist.map((elem) => merkleLeaf(elem));
  const merkleTree = new MerkleTree(merkleLeaves, keccak256, { sort: true });
  const proof = merkleTree.getHexProof(merkleLeaf(entry));
  return proof;
};

export const genPaymentAddresses = async (paymentSplitterFactory, opts) => {
  if (!opts) opts = {};

  const addresses = opts.addresses
    ? opts.addresses
    : [paymentSplitterFactory.signer.address];
  const shares = opts.shares ? opts.shares : [100];
  const charityGroupSplitters = [];
  for (let i = 0; i < 5; i++) {
    const charityGroupSplitter = await paymentSplitterFactory.deploy(
      addresses,
      shares
    );
    await charityGroupSplitter.deployed();
    charityGroupSplitters.push(charityGroupSplitter.address);
  }
  const artistAndDevSplitter = await paymentSplitterFactory.deploy(
    addresses,
    shares
  );
  await artistAndDevSplitter.deployed();
  const royaltySplitter = await paymentSplitterFactory.deploy(
    addresses,
    shares
  );
  await royaltySplitter.deployed();
  return {
    charityGroupSplitters: charityGroupSplitters,
    artistAndDevSplitter: artistAndDevSplitter.address,
    royaltySplitter: royaltySplitter.address,
  };
};

// The amount of per-baton donation to reach the level 3 when there are only
// four batons, one for each charity group.
export const donationForLvl3 = async (batons) => {
  const batonUpgradeEth = await batons.BATON_UPGRADE();
  const groupUpgradeEth = await batons.GROUP_UPGRADE();
  const projectUpgradeEth = await batons.PROJECT_UPGRADE();
  // Ceiling function with integer division. ceil(x / y) is (x + y - 1) // y.
  const quarterOfProjectEth = projectUpgradeEth.add(4 - 1).div(4);
  return [batonUpgradeEth, groupUpgradeEth, quarterOfProjectEth].reduce(
    (a, i) => (a.gt(i) ? a : i),
    ethers.constants.Zero
  );
};