import { useState, useEffect, useRef } from 'react';
import { ethers } from 'ethers';
import { useEthers } from '@usedapp/core';
import { getContractAddress } from 'components/Constants';
import BATONS_ABI from 'abi/Batons.json';

export function useBatons() {
  const [batons, setBatons] = useState(null);

  const { active, account, library, chainId } = useEthers();

  useEffect(() => {
    if (!active || !library || !account || !chainId) {
      // All dependencies need to be valid
      return;
    }

    let _batons = new ethers.Contract(
      getContractAddress(chainId),
      BATONS_ABI
    );
    _batons = _batons.connect(library.getSigner());
    setBatons(_batons);
  }, [library, account, active, chainId]);

  return batons;
}

export function usePublicBatons() {
  const [batons, setBatons] = useState(null);
  const { library, chainId } = useEthers();
  const activeLib = useRef();

  useEffect(() => {
    if (!library || activeLib.current || !chainId) { return }
    activeLib.current = library;

      console.log('use public batons contract address ' + getContractAddress(chainId));
    const batons = new ethers.Contract(
      getContractAddress(chainId),
      BATONS_ABI
    );

    setBatons(batons.connect(activeLib.current));
  }, [library, chainId]);

  return batons;
}